import mainApi from "../../plugins/axios/axios.js";
export default {
  state:{
    token: null,
    user:null
  },
  mutations:{
    setToken(state, payload) {
      state.token = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
  },
  actions:{
    login(context, payload) {
      return new Promise((resolve, reject) => {
        mainApi
          .post("/admin/auth/login", payload)
          .then((result) => {
            console.log(result.data.data.token);
            const token = result.data.data.token;
            const user = {
              id:result.data.data.id,
              name:result.data.data.name,
              email:result.data.data.email,
              score:result.data.data.score,
              level:result.data.data.level,
            }
            context.commit("setToken",token);
            context.commit("setUser",user);
            localStorage.setItem("token", token);
            localStorage.setItem("user", JSON.stringify(user));
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    logout(context) {
      if (localStorage.getItem("token")) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
      }
      context.commit("setToken",null);
      context.commit("setUser",null);
    },
  },
  getters:{
    token(state) {
      return state.token ? state.token : localStorage.getItem("token");
    },
    user(state) {
      return state.user ? state.user : localStorage.getItem("user");
    },
  }
}