<template>
  <section class="filter-box">
    <div class="d-flex flex-column">
      <!-- rows -->
      <v-row
      no-gutters
      style="max-width: 850px; box-shadow:0px 1px 3px 1px #00000029"
      class="added-rows pa-2 br-8 my-2"
      v-for="(row, i) in rows"
      :key="i"
    >
      <v-col cols="7" sm="4" 
        ><v-select
          v-model="row.column"
          :items="columns"
          :loading="isLoading"
          :rules="[rules.select2]"
          label="Column"
          :menu-props="{ bottom: true, offsetY: true }"
          outlined
          dense
        ></v-select
      ></v-col>
      <v-col cols="5" sm="3" 
        ><v-select
          v-model="row.condition"
          :items="conditions"
          :loading="isLoading"
          :rules="[rules.select2]"
          label="Condition"
          :menu-props="{ bottom: true, offsetY: true }"
          outlined
          dense
          class="ml-2 mx-sm-2"
        ></v-select
      ></v-col>
      <v-col cols="9" sm="3">
        <v-text-field
          outlined
          dense
          :rules="[rules.required]"
          label="Value"
          v-model="row.value"
        ></v-text-field
      ></v-col>
      <v-col cols="3" sm="2" >
        <div class=" d-flex justify-end">
        <v-btn color="error" class="ml-sm-3"  fab small text @click="deleteRow(i)"
          >
           <v-icon >mdi-delete</v-icon></v-btn
        >

        </div>
      </v-col>
    </v-row>
      <!-- rows -->

      <section class="filterItem lighten-4 w-100 mt-3">
        <v-form class="d-flex" ref="form">
          <v-row no-gutters style="max-width: 900px" >
            <v-col cols="12" sm="4"
              ><v-select
                v-model="selectedName"
                :items="columns"
                :loading="isLoading"
                :rules="[rules.select2]"
                item-text="title"
                item-value="id"
                label="Column"
                :menu-props="{ bottom: true, offsetY: true }"
                outlined
                dense
              ></v-select
            ></v-col>
            <v-col cols="12" sm="3"
              ><v-select
                v-model="selectedCondition"
                :items="conditions"
                :loading="isLoading"
                :rules="[rules.select2]"
                item-text="title"
                item-value="id"
                label="Condition"
                :menu-props="{ bottom: true, offsetY: true }"
                outlined
                dense
                class="mx-sm-2"
              ></v-select
            ></v-col>
            <v-col cols="12" sm="3">
              <v-text-field
                outlined
                dense
                :rules="[rules.required]"
                label="Value"
                v-model="value"
              ></v-text-field
            ></v-col>
            <v-col cols="12" sm="2">
              <v-btn
                class="d-none d-sm-block ml-5 "
                icon
                outlined
                color="primary"
                @click="addRows"
              >
                <v-icon color=""> mdi-plus </v-icon>
              </v-btn>
              <v-btn
                class="text-capitalize mb-2 d-sm-none"
                block
                outlined
                color="primary"
                @click="addRows"
              >
                Add Filter<v-icon right> mdi-plus </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </section>
    </div>
   
    <div class="d-flex justify-end">
      <v-btn
        color="success"
        class="apply-btn text-capitalize mt-1 d-none d-sm-block subtitle-2"
        @click="applyFilters"
        :disabled="rows.length <= 0"
        >Apply</v-btn
      >
      <v-btn
        color="success"
        block
        class="text-capitalize mt-1 d-sm-none"
        @click="applyFilters"
        :disabled="rows.length <= 0"
        >Apply</v-btn
      >
    </div>
  </section>
</template>

<script>
export default {
  props: ["columns", "conditions", "isLoading"],
  data() {
    return {
      rows: [],
      selectedName: "",
      selectedCondition: "",
      value: "",
      rules: {
        required: (v) => !!v || "This Field is Required",
        select2: (v) =>
          (!!v && v.length > 0) || "At least one feature must Be selected!",
      },
    };
  },
  created() {},
  methods: {
    addRows() {
      if (this.$refs.form.validate()) {
        let info = {
          column: this.selectedName,
          condition: this.selectedCondition,
          value: this.value,
        };
        this.rows.push(info);
        this.$refs.form.reset();
      }
    },
    deleteRow(i) {
      this.rows.splice(i, 1);
      if(this.rows.length<1){
        this.$emit("appliedFilters", this.rows);
      }
    },
    applyFilters() {
      this.$emit("appliedFilters", this.rows);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/style/colors.scss";
.filter-box {
  background: lighten($grey,42%);
  border-radius: 10px;
  padding: 10px;
  margin: 5px 0;
  .added-rows{
    background: lighten($deep-blue,55% );
  }
  .apply-btn {
    padding: 0 34px;
    height: 30px;
  }
}
</style>
