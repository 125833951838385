import mainApi from "../../plugins/axios/axios";
export default {
  state: {},
  mutations: {},
  actions: {
    getResources() {
      return new Promise((resolve, reject) => {
        mainApi
          .get("/admin/resources")
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    createResources(_, newResource) {
      return new Promise((resolve, reject) => {
        mainApi
          .post(`/admin/resources`,newResource)
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    deleteResources(_,id) {
      return new Promise((resolve, reject) => {
        mainApi
          .delete(`/admin/resources/${id}`)
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    editResources(_, info) {
      return new Promise((resolve, reject) => {
        mainApi
          .put(`/admin/resources/${info.id}`, {
            name: info.name,
            art_portrait: info.art_portrait,
            art_action: info.art_action,
            price: info.price,
            description: info.description,
          })
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    
    
  },
  getters: {},
};
