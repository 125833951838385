<template>
  <section class="sizes pa-3">
    <section class="d-flex justify-space-between">
      <v-breadcrumbs :items="items" divider="/" class="bread-crumb-items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            class="bread-crumb-item"
            :disabled="item.disabled"
            :to="item.to"
          >
            {{ item.name }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="back-button mr-5"
            outlined
            fab
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="$router.go(-1)"
          >
            <v-icon>mdi-undo-variant</v-icon>
          </v-btn>
        </template>
        <span>Go Back</span>
      </v-tooltip>
    </section>

    <v-card>
      <div class="table-title d-flex">
        <v-icon color="white" class="mr-3">mdi-strategy</v-icon>
        <h1>Sizes List</h1>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="add-button"
              dark
              fab
              small
              color="white"
              v-bind="attrs"
              v-on="on"
              @click="addDialog = true"
            >
              <v-icon dark color="black"> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Create Size</span>
        </v-tooltip>
      </div>
      <v-data-table
        :headers="headers"
        :items="localSizes"
        :loading="isLoading"
        :items-per-page="15"
        hide-default-footer
        class="table elevation-1"
      >
        <template v-slot:item.description="{ item }">{{
          item.description != null ? item.description : "--"
        }}</template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="showDetail(item)">
                <v-icon color="orange darken-1" small class="icon">mdi-information</v-icon>
              </button>
            </template>
            <span>Show Details</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="showEdit(item)">
                <v-icon color="green" small class="icon">mdi-pen</v-icon>
              </button>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="submitDelete(item)">
                <v-icon color="red" small class="icon">mdi-delete</v-icon>
              </button>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <div class="table-paginate-box">
      <div class="table-pagination-total">
        {{ total }} <span class="">result(s)</span>
      </div>
      <div class="paginate-box">
        <v-pagination
          v-model="page"
          class="pagination"
          :length="lastPage"
          :total-visible="3"
          @input="fetchSizes()"
          color="blue"
          circle
        ></v-pagination>
      </div>
    </div>
    <!--detail dialog -->
    <v-dialog v-model="detailDialog" width="500">
      <v-card class="card">
        <v-card-title class="card-title">
          <section class="w-100 d-flex justify-space-between align-center">
            <h5>Show Size Details</h5>
            <div class="d-flex">
              <h3 class="mr-3">
                ID: <span class="white--text">{{ itemSelected.id }}</span>
              </h3>
            </div>
          </section>
        </v-card-title>

        <v-card-text class="mt-2 card-text">
          <section class="my-5 d-flex" >
            <h1 class="mr-2"> Title:</h1>
            <h3>
                 {{itemSelected.title}}
              </h3>
          </section>
          <section class="my-5 d-flex" >
            <h1 class="mr-2"> Area:</h1>
            <h3>
                 {{itemSelected.area}}
              </h3>
          </section>
          <section class="my-5 d-flex" >
            <h1 class="mr-2"> width:</h1>
            <h3>
                 {{itemSelected.width}}
              </h3>
          </section>
          <section class="my-5 d-flex" >
            <h1 class="mr-2"> Height:</h1>
            <h3>
                 {{itemSelected.height}}
              </h3>
          </section>
          <section class="my-5 d-flex" >
            <h1 class="mr-2"> Description:</h1>
            <h3>
                 {{itemSelected.description}}
              </h3>
          </section>
          <section class="my-5 d-flex" >
            <h1 class="mr-2">Created at:</h1>
            <h3>
                 {{formatDate(itemSelected.created_at)}}
              </h3>
          </section>
          <section class="my-5 d-flex" >
            <h1 class="mr-2">Updated at:</h1>
            <h3>
                 {{formatDate(itemSelected.updated_at)}}
              </h3>
          </section>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="detailDialog = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--detail dialog -->
    <!-- add Size dialog -->
    <v-dialog v-model="addDialog" width="500">
      <v-card class="card">
        <v-card-title class="card-title">
          <h5>Add New Size</h5>
        </v-card-title>

        <v-card-text class="mt-2">
          <v-form class="form" v-model="valid" ref="form">
            <p class="my-2 grey--text">
              Please enter the Size information below.
            </p>
            <v-text-field
              rounded
              outlined
              dense
              type="number"
              label="Width"
              :rules="[rules.required]"
              v-model="width"
            ></v-text-field>
            <v-text-field
              rounded
              outlined
              dense
              type="number"
              label="Height"
              :rules="[rules.required]"
              v-model="height"
            ></v-text-field>
            <v-textarea
              outlined
              dense
              rows="3"
              label="Description"
              v-model="description"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="submitAdd"
            :loading="isLoading"
            :disabled="!valid"
          >
            Add Size
          </v-btn>
          <v-btn color="error" text @click="addDialog = false"> Cancle </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add Size dialog -->

    <!-- edit Size dialog -->
    <v-dialog v-model="editDialog" width="500">
      <v-card class="card">
        <v-card-title class="card-title">
          <h5>Edit Size</h5>
        </v-card-title>

        <v-card-text class="mt-2 card_text">
          <v-form class="form" v-model="valid">
            <p class="my-2 grey--text">
              Please enter the Size information below.
            </p>
            <v-text-field
              rounded
              outlined
              dense
              type="number"
              label="Width"
              v-model="editWidth"
            ></v-text-field>
            <v-text-field
              rounded
              outlined
              dense
              type="number"
              label="Height"
              v-model="editHeight"
            ></v-text-field>
            <v-textarea
              outlined
              dense
              rows="3"
              label="Description"
              v-model="editDescription"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="submitEdit" :loading="isLoading">
            Update Size
          </v-btn>
          <v-btn color="error" text @click="editDialog = false"> Cancle </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit Size dialog -->

    
  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      detailDialog: false,
      page: 1,
      lastPage: 0,
      total: 0,
      itemSelected: {},
      localSizes: [],
      // addDilaog
      addDialog: false,
      width:0,
      height:0,
      description: null,
      
      valid: true,
      // addDilaog

      //editDialog
      editDialog: false,
      editWidth: 0,
      editHeight: 0,
      editDescription:'',
      //editDialog

      items: [
        {
          name: "Sizes",
          disabled: true,
          to: "/home/sizes",
        },
      ],
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Area",
          value: "area",
        },
        { text: "Width", value: "width" },
        { text: "Height", value: "height" },
        { text: "Description", value: "description" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  computed: {
    ...mapGetters(["rules"]),
  },
  created() {
    this.fetchSizes();
  },
  methods: {
    ...mapActions([
      "getSizes",
      "createSizes",
      "deleteSizes",
      "editSizes",
    ]),
    fetchSizes() {
      this.isLoading = true;
      this.getSizes(this.page)
        .then((result) => {
          console.log(result);
          this.localSizes = result.data.data;
          this.total = result.data.meta.total;
          this.lastPage = result.data.meta.last_page;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showDetail(item) {
      this.detailDialog = true;
      this.itemSelected = item;
    },
    submitAdd() {
      if (this.$refs.form.validate()) {
        let newSize = {
          width: this.width,
          height: this.height,
          description:this.description
        };
        this.isLoading = true;
        this.createSizes(newSize)
          .then(() => {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.addDialog = false;
            this.fetchSizes();
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    submitDelete(item) {
      this.isLoading = true;
      this.deleteSizes(item.id)
        .then(() => {
          this.fetchSizes();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showEdit(item) {
      this.itemSelected = item;
      this.editDialog = true;
      this.editWidth = item.width;
      this.editHeight = item.height;
      this.editDescription = item.description
    },
    submitEdit() {
      let info = {
        id: this.itemSelected.id,
        width: this.editWidth,
        height: this.editHeight,
        description : this.editDescription
      };
      this.isLoading = true;
      this.editSizes(info)
        .then(() => {
          this.fetchSizes();
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
          this.editDialog = false;
        });
    },
    formatDate(date) {
      let newDate = new Date(date);
      return newDate.toLocaleString();
    },
  },
};
</script>

<style lang="scss" scoped>
.sizes {
  .add-button {
    position: absolute;
    right: 20px;
    right: 30px;
  }
  .table {
    padding-top: 45px;
    .v-icon {
      margin-right: 10px;
    }
  }
  ::v-deep .v-data-table .v-data-table__wrapper table {
    width: 100%;
    min-width: 930px;
  }
}
.card_text {
  height: auto !important;
  overflow: auto;
}
</style>
