import mainApi from "../../plugins/axios/axios";
export default {
  state: {},
  mutations: {},
  actions: {
    getTags(context) {
      return new Promise((resolve, reject) => {
        mainApi
          .get("/admin/tags")
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            context.commit("setError", "Failed To Fetch Tags List !",  { root: true });
            reject();
          });
      });
    },
    createTag(context, newTag) {
      return new Promise((resolve, reject) => {
        mainApi
          .post(`/admin/tags`,newTag)
          .then((result) => {
            console.log(result);
            context.commit("setError", `New Tag Created Successfully`, { root: true });
            resolve();
          })
          .catch((err) => {
            console.log(err);
            context.commit("setError", "Failed To Create Tag",{ root: true });
            reject();
          });
      });
    },
    deleteTag(context,id) {
      return new Promise((resolve, reject) => {
        mainApi
          .delete(`/admin/tags/${id}`)
          .then((result) => {
            console.log(result);
            context.commit("setError", `Tag ID: ${id} Deleted Successfully`, { root: true });
            resolve();
          })
          .catch((err) => {
            console.log(err);
            context.commit("setError", "Failed To Delete Tag",{ root: true });
            reject();
          });
      });
    },
    editTag(context, info) {
      return new Promise((resolve, reject) => {
        mainApi
          .put(`/admin/tags/${info.id}`, {
            title: info.title,
            description: info.description,
          })
          .then((result) => {
            console.log(result);
            context.commit(
              "setError",
              `Tag ID:${info.id} Updated Successfully`,{ root: true }
            );
            resolve();
          })
          .catch((err) => {
            console.log(err);
            context.commit("setError", "Failed To Edit Tag",{ root: true });
            reject();
          });
      });
    },
    
    
  },
  getters: {},
};
