import Vue from "vue";
import VueRouter from "vue-router";
import authRequierd from "../utils/authRequierd";
import loginCheck from "../utils/loginCheck";
import Lotteries from '../views/lotteries/Lotteries.vue'
import Awards from '../views/lotteries/Awards.vue'
import Resource from '../views/resource/Resource.vue'
import Tags from '../views/tags/Tags.vue'
import Configs from '../views/configs/Configs.vue'
import Categories from '../views/categories/Categories.vue'
import Sizes from '../views/sizes/Sizes.vue'
import Contact from '../views/contact/Contact.vue'
import Lands from '../views/lands/Lands.vue'
import LandTags from '../views/lands/LandTags.vue'
import Buildings from '../views/buildings/Buildings.vue'
import Units from '../views/units/Units.vue'
import NotFound from "../components/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    beforeEnter : authRequierd,
    redirect:'/dashboard',
    component: () =>
    import ( "../views/Home.vue"),
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () =>
        import (
            "../views/Dashboard.vue"
        ),
      },
      {
        path: "reports",
        name: "reports",
        component: () =>
        import (
            "../views/reports/Index.vue"
        ),
      },
      // {
      //   path: "users",
      //   name: "users",
      //   component: Users,
      // },
      // {
      //   path: "/users/:id",
      //   name: "usersDetail",
      //   component: Users,
      //   children: [
      //     { path: "accounts", name: "accounts", component: Accounts },
      //     { path: "activity", name: "activity", component: Activity },
      //     { path: "notification", name: "notification", component: Notification },
      //     { path: "payment", name: "payment", component: Payment },
      //     { path: "wallet", name: "wallet", component: Wallet },
      //   ],
      // },
      {
        path: "lotteries",
        name: "lotteries",
        component: Lotteries,
      },
      {
        path: "/lotteries/:id",
        name: "lotteriesDetail",
        component: Lotteries,
        children: [
          { path: "awards", name: "awards", component: Awards },
        ],
      },
      {
        path: "resource",
        name: "resource",
        component: Resource,
      },
      {
        path: "tags",
        name: "tags",
        component: Tags,
      },
      {
        path: "configs",
        name: "configs",
        component: Configs,
      },
     
      {
        path: "categories",
        name: "categories",
        component: Categories,
      },
      {
        path: "sizes",
        name: "sizes",
        component: Sizes,
      },
      
      {
        path: "contact",
        name: "contact",
        component: Contact,
      },
      {
        path: "lands",
        name: "lands",
        component: Lands,
      },
      {
        path: "/lands/:id",
        name: "landTags",
        component: LandTags,
        // children: [
        //   { path: "followers", name: "followers", component: Followers },
        //   { path: "logs", name: "logs", component: Logs },
        // ],
      },
      {
        path: "buildings",
        name: "buildings",
        component: Buildings,
      },
      {
        path: "units",
        name: "units",
        component: Units,
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    beforeEnter : loginCheck,
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  { path: "/:notFound(.*)", component: NotFound },
  
];

 

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
