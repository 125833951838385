<template>
  <section class="reports">
    <v-breadcrumbs :items="items" class="bread-crumb-items">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          class="bread-crumb-item"
          :disabled="item.disabled"
          :to="item.to"
        >
          {{ item.name }}
        </v-breadcrumbs-item>
      </template>
      <template v-slot:divider>
        <v-icon>mdi-forward</v-icon>
      </template>
    </v-breadcrumbs>
    <v-container>
      <v-row>
        <v-col>
          <header class="">
            <div class="tabs d-flex justify-center">
              <ul>
                <li
                  v-for="(tab, i) in tabs"
                  :key="i"
                  @click="changeTabs(tab)"
                  :class="tab.isActive ? 'active' : ''"
                >
                  {{ tab.name }}
                </li>
              </ul>
            </div>
          </header>
          <main>
            <Purchases v-if="tabs[0].isActive" />
            <Users v-if="tabs[1].isActive" />
            <Payments v-if="tabs[2].isActive" />
            <Withdrawals v-if="tabs[3].isActive" />
          </main>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import Purchases from "../../components/reports/Purchases.vue";
import Payments from "../../components/reports/Payments.vue";
import Users from "../../components/reports/Users.vue";
import Withdrawals from "../../components/reports/Withdrawals.vue";
import { mapActions } from 'vuex';
export default {
  data() {
    return {
      tabs: [
        {
          name: "Purchases",
          isActive: true,
        },
        {
          name: "users",
          isActive: false,
        },
        {
          name: "Payments",
          isActive: false,
        },
        {
          name: "Withdrawals",
          isActive: false,
        },
      ],
      items: [
        {
          name: "Reports",
          disabled: true,
          to: "/reports",
        },
      ],
    };
  },
  components: {
    Purchases,
    Payments,
    Users,
    Withdrawals,
  },
  created(){
    this.getTotalReportsData()
  },
  methods: {
    ...mapActions(["getTotalReportsData"]),
    changeTabs(item) {
      let oldTab = this.tabs.find((element) => element.isActive === true);
      oldTab.isActive = !oldTab.isActive;
      let newTab = this.tabs.find((element) => element.name === item.name);
      newTab.isActive = !newTab.isActive;
    },
  },
};
</script>

<style lang="scss" scoped>
.reports {
  .tabs {
    ul {
      width: 60%;
      list-style: none;
      display: flex;
      background: #ccd4e8;
      color: #8a93a7;
      border-radius: 8px;
      padding: 0;
      overflow: hidden;
      li {
        width: 25%;
        text-align: center;
        padding: 4px 0;
        font-weight: 400;
        cursor: pointer;
          transition: all .8s linear;
        &.active {
          background: #4c629b;
          color: white;
        }
      }
    }
  }
}
</style>
