<template>
  <section class="categories pa-3">
    <section class="d-flex justify-space-between">
      <v-breadcrumbs :items="items" divider="/" class="bread-crumb-items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            class="bread-crumb-item"
            :disabled="item.disabled"
            :to="item.to"
          >
            {{ item.name }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="back-button mr-5"
            outlined
            fab
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="$router.go(-1)"
          >
            <v-icon>mdi-undo-variant</v-icon>
          </v-btn>
        </template>
        <span>Go Back</span>
      </v-tooltip>
    </section>

    <v-card>
      <div class="table-title d-flex">
        <v-icon color="white" class="mr-3">mdi-strategy</v-icon>
        <h1>Categories List</h1>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="add-button"
              dark
              fab
              small
              color="white"
              v-bind="attrs"
              v-on="on"
              @click="addDialog = true"
            >
              <v-icon dark color="black"> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Create Category</span>
        </v-tooltip>
      </div>
      <v-data-table
        :headers="headers"
        :items="localcategories"
        :loading="isLoading"
        :items-per-page="15"
        hide-default-footer
        class="table elevation-1"
      >
        <template v-slot:item.description="{ item }">{{
          item.description != null ? item.description : "--"
        }}</template>
        <template v-slot:item.hex_code="{ item }">{{item.hex_code != null ? item.hex_code : "--"}}</template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="showDetail(item)">
                <v-icon color="orange darken-1" small class="icon">mdi-information</v-icon>
              </button>
            </template>
            <span>Show Details</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="showEdit(item)">
                <v-icon color="green" small class="icon">mdi-pen</v-icon>
              </button>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="submitDelete(item)">
                <v-icon color="red" small class="icon">mdi-delete</v-icon>
              </button>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <div class="table-paginate-box">
      <div class="table-pagination-total">
        {{ total }} <span class="">result(s)</span>
      </div>
      <div class="paginate-box">
        <v-pagination
          v-model="page"
          class="pagination"
          :length="lastPage"
          :total-visible="3"
          @input="fetchCategories()"
          color="blue"
          circle
        ></v-pagination>
      </div>
    </div>
    <!--detail dialog -->
    <v-dialog v-model="detailDialog" width="800">
      <v-card class="card">
        <v-card-title class="card-title">
          <section class="w-100 d-flex justify-space-between align-center">
            <h5>Show Category Details</h5>
            <div class="d-flex">
              <h3 class="mr-3">
                ID: <span class="white--text">{{ itemSelected.id }}</span>
              </h3>
            </div>
          </section>
        </v-card-title>

        <v-card-text class="mt-2 card-edit-text">
          <section class="my-5 d-flex" >
            <h1 class="mr-2"> Title:</h1>
            <h3>
                 {{itemSelected.title}}
              </h3>
          </section>
          <section class="my-5 d-flex" >
            <h1 class="mr-2"> Parent Title:</h1>
            <h3>
                 {{itemSelected.parent_title}}
              </h3>
          </section>
          <section class="my-5 d-flex" >
            <h1 class="mr-2"> Description:</h1>
            <h3>
                 {{itemSelected.description}}
              </h3>
          </section>
          <section class="my-5 d-flex" >
            <h1 class="mr-2"> Hex Code:</h1>
            <h3>
                 {{itemSelected.hex_code}}
              </h3>
          </section>
          <section class="my-5 d-flex" >
            <h1 class="mr-2"> Art Portrait:</h1>
            <h3>
                 {{itemSelected.art_portrait}}
              </h3>
          </section>
          <section class="my-5 d-flex" >
            <h1 class="mr-2">Art Action:</h1>
            <h3>
                 {{itemSelected.art_action}}
              </h3>
          </section>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="detailDialog = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--detail dialog -->
    <!-- add category dialog -->
    <v-dialog v-model="addDialog" width="500">
      <v-card class="card">
        <v-card-title class="card-title">
          <h5>Add New category</h5>
        </v-card-title>

        <v-card-text class="mt-2">
          <v-form class="form" v-model="valid" ref="form">
            <p class="my-2 grey--text">
              Please enter the Category information below.
            </p>
            <v-text-field
              rounded
              outlined
              dense
              label="Title"
              v-model="title"
              :rules="[rules.required, rules.maxLength, rules.minLength]"
            ></v-text-field>
            <v-text-field
              rounded
              outlined
              dense
              type="number"
              label="Parent ID"
              :rules="[rules.required]"
              v-model="parent_id"
            ></v-text-field>
            <v-text-field
              rounded
              outlined
              dense
              label="Art Portrait"
              v-model="art_portrait"
            ></v-text-field>
            <v-text-field
              rounded
              outlined
              dense
              label="Art Action"
              v-model="art_action"
            ></v-text-field>
            <v-text-field
              rounded
              outlined
              dense
              type="number"
              label="Hex Code"
              v-model="hex_code"
            ></v-text-field>
            <v-textarea
              outlined
              dense
              rows="3"
              label="Description"
              v-model="description"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="submitAdd"
            :loading="isLoading"
            :disabled="!valid"
          >
            Add Category
          </v-btn>
          <v-btn color="error" text @click="addDialog = false"> Cancle </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add Category dialog -->

    <!-- edit Category dialog -->
    <v-dialog v-model="editDialog" width="500">
      <v-card class="card">
        <v-card-title class="card-title">
          <h5>Edit Category</h5>
        </v-card-title>

        <v-card-text class="mt-2 card_text">
          <v-form class="form" v-model="valid">
            <p class="my-2 grey--text">
              Please enter the Category information below.
            </p>
            <v-text-field
              rounded
              outlined
              dense
              label="Title"
              v-model="editTitle"
              :rules="[rules.required, rules.maxLength, rules.minLength]"
            ></v-text-field>
            <v-text-field
              rounded
              outlined
              dense
              type="number"
              label="parent Id"
              v-model="editParent_id"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="submitEdit" :loading="isLoading">
            Update Category
          </v-btn>
          <v-btn color="error" text @click="editDialog = false"> Cancle </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit Category dialog -->

  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      detailDialog: false,
      page: 1,
      lastPage: 0,
      total: 0,
      itemSelected: {},
      localcategories: [],
      // addDilaog
      addDialog: false,
      title: "",
      parent_id:0,
      description: null,
      hex_code:null,
      art_portrait:null,
      art_action:null,
      
      valid: true,
      // addDilaog

      //editDialog
      editDialog: false,
      editTitle: "",
      editParent_id: null,
      //editDialog

      items: [
        {
          name: "Categories",
          disabled: true,
          to: "/home/categories",
        },
      ],
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Parent Title",
          value: "parent_title",
        },
        { text: "Description", value: "description" },
        { text: "Hex Code", value: "hex_code" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  computed: {
    ...mapGetters(["rules"]),
  },
  created() {
    this.fetchCategories();
  },
  methods: {
    ...mapActions([
      "getCategories",
      "createCategories",
      "deleteCategories",
      "editCategories",
    ]),
    fetchCategories() {
      this.isLoading = true;
      this.getCategories(this.page)
        .then((result) => {
          console.log(result);
          this.localcategories = result.data.data;
          this.total = result.data.meta.total;
          this.lastPage = result.data.meta.last_page;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showDetail(item) {
      this.detailDialog = true;
      this.itemSelected = item;
    },
    submitAdd() {
      if (this.$refs.form.validate()) {
        let newCategory = {
          title: this.title,
          parent_id: this.parent_id,
          hex_code: this.hex_code,
          art_portrait:this.art_portrait,
          art_action:this.art_action,
          description:this.description
        };
        this.isLoading = true;
        this.createCategories(newCategory)
          .then(() => {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.addDialog = false;
            this.fetchCategories();
          })
          .finally(() => {
            this.snackbar = true;
            this.isLoading = false;
          });
      }
    },
    submitDelete(item) {
      this.isLoading = true;
      this.deleteCategories(item.id)
        .then(() => {
          this.fetchCategories();
        })
        .finally(() => {
          this.snackbar = true;
          this.isLoading = false;
        });
    },
    showEdit(item) {
      this.itemSelected = item;
      this.editDialog = true;
      this.editTitle = item.title;
      this.editParent_id = item.parent_id;
    },
    submitEdit() {
      let info = {
        id: this.itemSelected.id,
        title: this.editTitle,
        parent_id: this.editParent_id,
      };
      this.isLoading = true;
      this.editCategories(info)
        .then(() => {
          this.fetchCategories();
        })
        .catch(() => {})
        .finally(() => {
          this.snackbar = true;
          this.isLoading = false;
          this.editDialog = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.categories {
  .add-button {
    position: absolute;
    right: 20px;
    right: 30px;
  }
  .table {
    padding-top: 45px;
    .v-icon {
      margin-right: 10px;
    }
  }
  ::v-deep .v-data-table .v-data-table__wrapper table {
    width: 100%;
    min-width: 930px;
  }
}
.card_text {
  height: auto !important;
  overflow: auto;
}
</style>
