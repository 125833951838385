<template>
  <section class="landTags pa-3">
    <section class="d-flex justify-space-between">
      <v-breadcrumbs :items="items" divider="/" class="bread-crumb-items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            class="bread-crumb-item"
            :disabled="item.disabled"
            @click="$router.push(item.to)"
          >
            {{ item.name }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="back-button mr-5"
            outlined
            fab
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="$router.go(-1)"
          >
            <v-icon>mdi-undo-variant</v-icon>
          </v-btn>
        </template>
        <span>Go Back</span>
      </v-tooltip>
    </section>

    <v-card>
      <div class="table-title d-flex">
        <v-icon color="white" class="mr-3">mdi-strategy</v-icon>
        <h1>Tags List</h1>
      </div>
      <v-data-table
        :headers="headers"
        :items="localLandTags"
        :loading="isLoading"
        :items-per-page="15"
        hide-default-footer
        class="table elevation-1"
      >
        <template v-slot:item.description="{ item }">{{
          item.description != null ? item.description : "--"
        }}</template>
        <template v-slot:item.created_at="{ item }">{{
          formatDate(item.created_at)
        }}</template>
        <template v-slot:item.updated_at="{ item }">{{
          formatDate(item.updated_at)
        }}</template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="showDetail(item)">
                <v-icon color="orange darken-1" small class="icon">mdi-information</v-icon>
              </button>
            </template>
            <span>Show Details</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="submitDelete(item)">
                <v-icon color="red" small class="icon">mdi-delete</v-icon>
              </button>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <div class="table-paginate-box">
      <div class="table-pagination-total">
        {{ total }} <span class="">result(s)</span>
      </div>
      <div class="paginate-box">
        <v-pagination
          v-model="page"
          class="pagination"
          :length="lastPage"
          :total-visible="5"
          @input="fetchLandTags()"
          color="blue"
          circle
        ></v-pagination>
      </div>
    </div>
    <!--detail dialog -->
    <v-dialog v-model="detailDialog" width="800">
      <v-card class="card">
        <v-card-title class="card-title">
          <section class="w-100 d-flex justify-space-between align-center">
            <h5>Show Land Tags Detail</h5>
            <div class="d-flex">
              <h3 class="mr-3">
                ID: <span class="white--text">{{ itemSelected.id }}</span>
              </h3>
            </div>
          </section>
        </v-card-title>

        <v-card-text class="mt-2 card-text">
          <section class="my-5 d-flex">
            <h1 class="mr-2">Title:</h1>
            <h3>
              {{ itemSelected.title }}
            </h3>
          </section>
          <section class="my-5 d-flex">
            <h1 class="mr-2">Description:</h1>
            <h3>
              {{ itemSelected.description }}
            </h3>
          </section>
          <section class="my-5 d-flex">
            <h1 class="mr-2">Created at:</h1>
            <h3>
              {{ formatDate(itemSelected.created_at) }}
            </h3>
          </section>
          <section class="my-5 d-flex">
            <h1 class="mr-2">Updated at:</h1>
            <h3>
              {{ formatDate(itemSelected.updated_at) }}
            </h3>
          </section>
          <v-divider class="mb-2 black" ></v-divider>
          <section v-if="itemSelected.pivot">
            <h1 class="text-h5 black--text">Pivot</h1>
            <section class="my-5 d-flex">
              <h1 class="mr-2">Taggable id:</h1>
              <h3>
                {{ itemSelected.pivot.taggable_id }}
              </h3>
            </section>
            <section class="my-5 d-flex">
              <h1 class="mr-2">Tag id:</h1>
              <h3>
                {{ itemSelected.pivot.tag_id }}
              </h3>
            </section>
            <section class="my-5 d-flex">
              <h1 class="mr-2">Taggable type:</h1>
              <h3>
                {{ itemSelected.pivot.taggable_type }}
              </h3>
            </section>
          </section>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="detailDialog = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--detail dialog -->
    
    
  </section>
</template>
<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      detailDialog: false,
      page: 1,
      lastPage: 1,
      total: 0,
      itemSelected: {},
      localLandTags: [],
      items: [
        {
          name: "Lands",
          disabled: false,
          to: "/lands",
        },
        {
          name: "Land Tags",
          disabled: true,
          to: "",
        },
      ],
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Title",
          value: "title",
        },
        {
          text: "Desctiption",
          value: "description",
        },
        { text: "Created at", value: "created_at" },
        { text: "Updated at", value: "updated_at" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  created() {
    this.fetchLandTags();
  },
  methods: {
    ...mapActions(["getLandTags","deleteLandTags"]),
    fetchLandTags() {
      this.isLoading = true;
      const id = this.$route.params.id;
      this.getLandTags(id)
        .then((result) => {
          this.localLandTags = result.data.result;
          this.total = this.localLandTags.length;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showDetail(item) {
      this.detailDialog = true;
      this.itemSelected = item;
    },
    
    submitDelete(item) {
      let info ={
        routeId:this.$route.params.id,
        tagId:item.id
      }
      this.isLoading = true;
      this.deleteLandTags(info)
        .then(() => {
          this.fetchLandTags();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    
    formatDate(date) {
      let newDate = new Date(date);
      return newDate.toLocaleString();
    },
  },
};
</script>

<style lang="scss" scoped>
.landTags {
  .add-button {
    position: absolute;
    right: 20px;
    right: 30px;
  }
  .table {
    padding-top: 45px;
    .v-icon {
      margin-right: 10px;
    }
  }
  ::v-deep .v-data-table .v-data-table__wrapper table {
    width: 100%;
    min-width: 930px;
  }
}
.card-text {
  height: 500px !important;
  overflow: auto;
}
</style>
