<template>
  <section class="resource pa-3">
    <section class="d-flex justify-space-between">
      <v-breadcrumbs :items="items" divider="/" class="bread-crumb-items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            class="bread-crumb-item"
            :disabled="item.disabled"
            :to="item.to"
          >
            {{ item.name }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="back-button mr-5"
            outlined
            fab
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="$router.go(-1)"
          >
            <v-icon>mdi-undo-variant</v-icon>
          </v-btn>
        </template>
        <span>Go Back</span>
      </v-tooltip>
    </section>

    <v-card>
      <div class="table-title d-flex">
        <v-icon color="white" class="mr-3">mdi-strategy</v-icon>
        <h1>Resources List</h1>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="add-button"
              dark
              fab
              small
              color="white"
              v-bind="attrs"
              v-on="on"
              @click="addDialog = true"
            >
              <v-icon dark color="black"> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Create Resource</span>
        </v-tooltip>
      </div>
      <v-data-table
        :headers="headers"
        :items="localResource"
        :loading="isLoading"
        :items-per-page="15"
        hide-default-footer
        class="table elevation-1"
      >
        <template v-slot:item.created_at="{ item }">{{
          formatDate(item.created_at)
        }}</template>
        <template v-slot:item.updated_at="{ item }">{{
          formatDate(item.updated_at)
        }}</template>
        <template v-slot:item.description="{ item }">{{
          item.description != null ? item.description : "--"
        }}</template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="showDetail(item)">
                <v-icon color="orange darken-1" small class="icon">mdi-information</v-icon>
              </button>
            </template>
            <span>Show Details</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="showEdit(item)">
                <v-icon color="green" small class="icon">mdi-pen</v-icon>
              </button>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="submitDelete(item)">
                <v-icon color="red" small class="icon">mdi-delete</v-icon>
              </button>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <div class="table-paginate-box">
      <div class="table-pagination-total">
        {{ total }} <span class="">result(s)</span>
      </div>
      <div class="paginate-box">
        <v-pagination
          v-model="page"
          class="pagination"
          :length="lastPage"
          :total-visible="3"
          @input="fetchResources()"
          color="blue"
          circle
        ></v-pagination>
      </div>
    </div>

    <!--detail dialog -->
    <v-dialog v-model="detailDialog" width="500">
      <v-card class="card">
        <v-card-title class="card-title">
          <section class="w-100 d-flex justify-space-between align-center">
            <h5>Show Resource Details</h5>
            <div class="d-flex">
              <h3 class="mr-3">
                ID: <span class="white--text">{{ itemSelected.id }}</span>
              </h3>
            </div>
          </section>
        </v-card-title>

        <v-card-text class="mt-2 card-text">
          <section class="my-5 d-flex" >
            <h1 class="mr-2"> Name:</h1>
            <h3>
                 {{itemSelected.name}}
              </h3>
          </section>
          <section class="my-5 d-flex" >
            <h1 class="mr-2"> Art Portrait:</h1>
            <h3>
                 {{itemSelected.art_portrait}}
              </h3>
          </section>
          <section class="my-5 d-flex" >
            <h1 class="mr-2"> Art Action:</h1>
            <h3>
                 {{itemSelected.art_action}}
              </h3>
          </section>
          <section class="my-5 d-flex" >
            <h1 class="mr-2"> Price:</h1>
            <h3>
                 {{itemSelected.price}}
              </h3>
          </section>
          <section class="my-5 d-flex" >
            <h1 class="mr-2"> Description:</h1>
            <h3>
                 {{itemSelected.description}}
              </h3>
          </section>
          
          <section class="my-5 d-flex" >
            <h1 class="mr-2">Created at:</h1>
            <h3>
                 {{formatDate(itemSelected.created_at)}}
              </h3>
          </section>
          <section class="my-5 d-flex" >
            <h1 class="mr-2">Updated at:</h1>
            <h3>
                 {{formatDate(itemSelected.updated_at)}}
              </h3>
          </section>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="detailDialog = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--detail dialog -->

    <!-- add Resource dialog -->
    <v-dialog v-model="addDialog" width="500">
      <v-card class="card">
        <v-card-title class="card-title">
          <h5>Add New Resource</h5>
        </v-card-title>

        <v-card-text class="mt-2">
          <v-form class="form" v-model="valid" ref="form">
            <p class="my-2 grey--text">
              Please enter the plan information below.
            </p>
            <v-text-field
              rounded
              outlined
              dense
              label="Name"
              v-model="name"
              :rules="[rules.required, rules.maxLength, rules.minLength]"
            ></v-text-field>
            <v-text-field
              rounded
              outlined
              dense
              label="Art Portrait"
              v-model="art_portrait"
            ></v-text-field>
            <v-text-field
              rounded
              outlined
              dense
              label="Art Action"
              v-model="art_action"
            ></v-text-field>
            <v-text-field
              rounded
              outlined
              dense
              type="number"
              label="Price"
              v-model="price"
              :rules="[rules.required]"
            ></v-text-field>
            <v-textarea
              outlined
              dense
              rows="3"
              label="Description"
              v-model="description"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="submitAdd"
            :loading="isLoading"
            :disabled="!valid"
          >
            Add Resource
          </v-btn>
          <v-btn color="error" text @click="addDialog = false"> Cancle </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add Resource dialog -->

    <!-- edit Resource dialog -->
    <v-dialog v-model="editDialog" width="500">
      <v-card class="card">
        <v-card-title class="card-title">
          <h5>Edit Resource</h5>
        </v-card-title>

        <v-card-text class="mt-2 card_text">
          <v-form class="form" v-model="valid">
            <p class="my-2 grey--text">
              Please enter the plan information below.
            </p>
            <v-text-field
              rounded
              outlined
              dense
              label="Name"
              v-model="editName"
              :rules="[rules.required, rules.maxLength, rules.minLength]"
            ></v-text-field>
            <v-text-field
              rounded
              outlined
              dense
              label="Art Portrait"
              v-model="editArt_portrait"
            ></v-text-field>
            <v-text-field
              rounded
              outlined
              dense
              label="Art Action"
              v-model="editArt_action"
            ></v-text-field>
            <v-text-field
              rounded
              outlined
              dense
              type="number"
              label="Price"
              v-model="editPrice"
              :rules="[rules.required]"
            ></v-text-field>
            <v-textarea
              outlined
              dense
              rows="3"
              label="Description"
              v-model="editDescription"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="submitEdit" :loading="isLoading">
            Update Resource
          </v-btn>
          <v-btn color="error" text @click="editDialog = false"> Cancle </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit Resource dialog -->

  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      detailDialog: false,
      page: 1,
      lastPage: 0,
      total: 0,
      itemSelected: {},
      localResource: [],
      // addDilaog
      addDialog: false,
      name:'',
      art_portrait:'',
      art_action:'',
      price: 0,
      description: "",
      
      valid: true,
      // addDilaog

      //editDialog
      editDialog: false,
      editName: "",
      editArt_portrait:"",
      editArt_action:"",
      editPrice:"",
      editDescription: "",
      //editDialog

      items: [
        {
          name: "Resource",
          disabled: true,
          to: "/home/resource",
        },
      ],
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Price",
          value: "price",
        },
        { text: "Description", value: "description" },
        { text: "Created at", value: "created_at" },
        { text: "Updated at", value: "updated_at" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  computed: {
    ...mapGetters(["errorMessage","rules"]),
  },
  created() {
    this.fetchResources();
  },
  methods: {
    ...mapActions(["getResources", "createResources", "deleteResources", "editResources"]),
    fetchResources() {
      this.isLoading = true;
      this.getResources()
        .then((result) => {
          console.log(result);
          this.localResource = result.data.data;
          this.total = this.localResource.length;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showDetail(item) {
      this.detailDialog = true;
      this.itemSelected = item;
    },
    submitAdd() {
      if (this.$refs.form.validate()) {
        let newResources = {
          name:this.name,
          art_portrait : this.art_portrait,
          art_action : this.art_action,
          price: this.price,
          description: this.description,
        };
        this.isLoading = true;
        this.createResources(newResources)
          .then(() => {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.addDialog = false;
            this.fetchResources();
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    submitDelete(item) {
      this.isLoading = true;
      this.deleteResources(item.id)
        .then(() => {
          this.fetchResources();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showEdit(item) {
      this.itemSelected = item;
      this.editDialog = true;
      this.editName = item.name;
      this.editArt_portrait = item.art_portrait;
      this.editArt_action = item.art_action;
      this.editPrice = item.price
      this.editDescription = item.description;
    },
    submitEdit() {
      let info = {
        id: this.itemSelected.id,
        name: this.editName,
        art_portrait : this.editArt_portrait,
        art_action : this.editArt_action,
        price:this.editPrice,
        description: this.editDescription,
      };
      this.isLoading = true;
      this.editResources(info)
        .then(() => {
          this.fetchResources();
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
          this.editDialog = false;
        });
    },
    formatDate(date) {
      let newDate = new Date(date);
      return newDate.toLocaleString();
    },
  },
};
</script>

<style lang="scss" scoped>
.resource {
  .add-button {
    position: absolute;
    right: 20px;
    right: 30px;
  }
  .table {
    padding-top: 45px;
    .v-icon {
      margin-right: 10px;
    }
  }
  ::v-deep .v-data-table .v-data-table__wrapper table {
    width: 100%;
    min-width: 930px;
  }
}
.card_text {
  height: auto !important;
  overflow: auto;
}
</style>
