import Vue from "vue";
import Vuex from "vuex";
import mainApi from "../plugins/axios/axios.js";
import auth from "./auth/auth.js";
import rules from "./rules/rules.js";
import alert from "./alert/alert.js";
import config from "./config/config.js";
import tags from "./tags/tags.js";
import resources from "./resources/resources.js";
import contacts from "./contacts/contacts.js";
import lotteries from "./lotteries/lotteries.js";
import categories from "./categories/categories.js";
import sizes from "./sizes/sizes.js";
import lands from "./lands/lands.js";
import buildings from "./buildings/buildings.js";
import units from "./units/units.js";
import reports from "./reports/reports.js";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    rules,
    alert,
    config,
    tags,
    resources,
    contacts,
    lotteries,
    categories,
    sizes,
    lands,
    buildings,
    units,
    reports
  },
  state: {
    errorMessage: null,
    newPage: "",
  },
  mutations: {
   
    setError(state, payload) {
      state.errorMessage = payload;
    },
    changePage(state, payload) {
      state.newPage = payload;
    },
  },
  actions: {
    changePage({ commit }, payload) {
      commit("changePage", payload);

      setTimeout(() => {
        commit("changePage", "");
      }, 100);
    },
    
    getTotalLands() {
      return new Promise((resolve, reject) => {
        mainApi
          .get("/admin/reports/total-land-sales")
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    getTotalCategoryLands() {
      return new Promise((resolve, reject) => {
        mainApi
          .get("/admin/reports/total-land-category-sales")
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    
    
  },
  getters: {
    
    errorMessage(state) {
      return state.errorMessage;
    },
   
    dashboardInfo(state) {
      return state.dashboardInfo;
    },
    isLoggedin() {
      return !!localStorage.getItem("token");
    },
    newPage(state) {
      return state.newPage;
    },
  },
});
