import mainApi from "../../plugins/axios/axios";
export default {
  state: {},
  mutations: {},
  actions: {
    getContacts() {
      return new Promise((resolve, reject) => {
        mainApi
          .get("/admin/contact-us")
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    createContacts(_, newContacts) {
      return new Promise((resolve, reject) => {
        mainApi
          .post(`/admin/contact_us`,newContacts)
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    deleteContacts(_,id) {
      return new Promise((resolve, reject) => {
        mainApi
          .delete(`/admin/contact_us/${id}`)
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    editContacts(_, info) {
      console.log(info);
      return new Promise((resolve, reject) => {
        mainApi
          .put(`/admin/contact_us/${info.id}`, {
            name: info.name,
            email: info.email,
            body: info.body,
          })
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    
    
  },
  getters: {},
};
