import mainApi from "../../plugins/axios/axios";
export default {
  state: {},
  mutations: {},
  actions: {
    getCategories(_,page) {
      return new Promise((resolve, reject) => {
        mainApi
          .get("/admin/categories",{params:{page:page}})
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    createCategories(_, newCategory) {
      console.log(newCategory);
      return new Promise((resolve, reject) => {
        mainApi
          .post(`/admin/categories`,newCategory)
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    deleteCategories(_,id) {
      return new Promise((resolve, reject) => {
        mainApi
          .delete(`/admin/categories/${id}`)
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    editCategories(_, info) {
      console.log(info);
      return new Promise((resolve, reject) => {
        mainApi
          .put(`/admin/categories/${info.id}`, {
            title: info.title,
            parent_id: info.parent_id,
          })
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    
    
  },
  getters: {},
};
