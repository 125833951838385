<template>
  <section class="history pa-3">
    <section class="d-flex justify-space-between">
      <v-breadcrumbs :items="items" divider="/" class="bread-crumb-items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            class="bread-crumb-item"
            :disabled="item.disabled"
            @click="$router.push(item.to)"
          >
            {{ item.name }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="back-button mr-5"
            outlined
            fab
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="$router.go(-1)"
          >
            <v-icon>mdi-undo-variant</v-icon>
          </v-btn>
        </template>
        <span>Go Back</span>
      </v-tooltip>
    </section>

    <v-card>
      <div class="table-title d-flex">
        <v-icon color="white" size="32" class="mr-3 mb-1">mdi-history</v-icon>
        <h1>History List for Signal ID: {{ $route.params.id }}</h1>
      </div>
      <v-data-table
        :headers="headers"
        :items="localHistorys"
        :loading="isLoading"
        :items-per-page="15"
        hide-default-footer
        class="table elevation-1"
      >
      </v-data-table>
    </v-card>

    <div class="table-paginate-box">
      <div class="table-pagination-total">
        {{ total }} <span class="">result(s)</span>
      </div>
      <div class="paginate-box">
        <v-pagination
          v-model="page"
          class="pagination"
          :length="lastPage"
          :total-visible="7"
          @input="getHistorys()"
          color="blue"
          circle
        ></v-pagination>
      </div>
    </div>
    
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      localHistorys: [],
      isLoading:false,
      page: 1,
      lastPage: null,
      total: 0,
      headers: [
        { text: "ID", value: "id" },
        {
          text: "Order Type",
          value: "order_type",
        },
        { text: "Open Price", value: "open_price" },
        { text: "Side", value: "side" },
        { text: "Stop Price", value: "stop_price" },
        { text: "Signal Time", value: "signal_time" },
        { text: "Symbol", value: "symbol" },
        { text: "Status", value: "status" },
      ],
      items: [
        {
          name: "Signals",
          disabled: false,
          to: "/signals",
        },
        {
          name: "History",
          disabled: true,
          to: `/signals/${this.$route.params.id}/history`,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['historys']),
  },
  created() {
    this.fetchHistorys();
  },
  methods: {
    ...mapActions(['getHistorys']),
    fetchHistorys() {
      let info = {
        signalId: this.$route.params.id,
        page: this.page,
      };
      this.isLoading = true;
      this.getHistorys( info)
        .then(() => {
          this.localHistorys = this.historys.data;
          this.total = this.historys.total;
          this.lastPage = this.historys.last_page;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.history {
  .table {
    padding-top: 45px;
  }
  ::v-deep .v-data-table .v-data-table__wrapper table {
    width: 100%;
    min-width: 1000px;
  }
}
</style>
