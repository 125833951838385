import axios from "axios";
import  store  from "../../store/index.js";
const mainApi = axios.create({
    baseURL: process.env.VUE_APP_ROOT_API,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        'Access-Control-Allow-Origin' : '*',
    }
});

mainApi.interceptors.request.use(
    config => {
        const token = store.getters.token;
        if (token) {
            config.headers.common["Authorization"] = "Bearer " + token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    },
);
mainApi.interceptors.response.use(
    response => {
        if(response.data.success && typeof(response.data.message) === "string" ?!response.data.message.includes("fetched") : response.data.message){
            store.dispatch("setAlert", {
                message: response.data.message,
                type: "success",
            });
        }
        return Promise.resolve(response);
    },
    error => {
        let status = error.response.status
        switch (status) {
            // token expire or wrong token
            case 401:
                if (
                    window.location.pathname != "/login"
                ) {
                    localStorage.removeItem("token");
                    window.location = "/login";
                }
                break;
            case 403:
                
                break;
            default:
                break;
        }

        if (error.response.data.result.length && error.response.data.message) {
            for (const [key, value] of Object.entries(error.response.data.result)) {
                let val = value.toString();

                store.dispatch("setAlert", {
                    title: key,
                    message: val,
                    type: "error",
                });
            }
        }

        if (Array.isArray(error.response.data.errors) && error.response.data.errors && error.response.data.message) {
            for (const [key, value] of Object.entries(error.response.data.errors)) {
                let val = value.toString();

                store.dispatch("setAlert", {
                    title: key,
                    message: val,
                    type: "error",
                });
            }
        }

        if (
            error.response.data.message &&
            !Array.isArray(error.response.data.errors) &&
            !error.response.data.result.length
        ) {
            store.dispatch("setAlert", {
                message: error.response.data.message,
                type: "error",
            });
        }

        return Promise.reject(error);
    },
    
);

export default mainApi;