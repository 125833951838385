<template>
  <section class="login-container d-flex justify-center align-center">
    <section class="card d-flex flex-column align-center br-12 px-4">
      <section class="sign-in d-flex flex-column align-center mt-n5 br-8 py-5">
        <h1 class="white--text mb-5 text-h5 font-weight-bold">Sign in</h1>
        <div class="d-flex mb-5 ">
          <v-icon color="white" >mdi-facebook</v-icon>
          <v-icon color="white" class="mx-6">mdi-github</v-icon>
          <v-icon color="white">mdi-google</v-icon>
        </div>
      </section>
      <section class="content mt-10">
        <v-text-field
          label="Email"
          type="email"
          :rules="[rules.required,rules.emailRules]"
          outlined
          dense
          append-icon="mdi-email-outline"
          v-model="email"
        ></v-text-field>
        <v-text-field
          label="Password"
          :type="`${passwordIsVisible ? 'text' : 'password'}`"
          :rules="[rules.required]"
          outlined
          dense
          :append-icon="`${passwordIsVisible ? 'mdi-eye': 'mdi-eye-off'}`"
          @click:append="passwordIsVisible = !passwordIsVisible"
          v-model="password"
        ></v-text-field>
        <v-switch
          v-model="isRemember"
          color="primary"
          label="Remember me"
          class="ma-0"
        ></v-switch>
      </section>
      <button @click="confirmLogin" class="br-8 py-2 my-8 white--text">
        Sign In
      </button>
    </section>
 
  </section>
</template>


<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      isRemember: false,
      email: "",
      password: "",
      passwordIsVisible:false,
    };
  },
  computed:{
    ...mapGetters(['token','isLoggedin','rules']),
  },
  methods: {
    ...mapActions(['login']),
    confirmLogin() {
      const data = {
        email: this.email,
        password: this.password,
      };
      this.login(data).then(() => {
        this.$router.push({name: 'dashboard'})
      })
      
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/colors.scss";
.login-container {
  height: 100vh;
  overflow: hidden;
  position: relative;
  &::before {
    content: "";
    background-image: url("../assets/image/login-bg.jpg");
    background-size: cover;
    background-position: bottom;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.9;
  }
  .card {
    width: 95%;
    min-height: 450px;
    height: max-content;
    max-width: 400px;
    background-color: $white;
    z-index: 2;
    .sign-in {
      width: 100%;
      background-image: linear-gradient(195deg, $pink-lighten, $pink);
      box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14),
        0 7px 10px -5px rgba(25, 14, 119, 0.4) !important;
    }
    .content {
      width: 95%;
    }
    button {
      cursor: pointer;
      width: 95%;
      transition: all 0.3s ease;
      background-image: linear-gradient(195deg, $pink-lighten, $pink);
      box-shadow: 0 3px 3px 0 rgb(233 30 99 / 15%),
        0 3px 1px -2px rgb(233 30 99 / 20%), 0 1px 5px 0 rgb(233 30 99 / 15%);
      &:hover {
        transition: all 0.3s ease;
        box-shadow: 0 10px 10px 0 rgb(21 32 128 / 30%),
          0 3px 1px -2px rgb(21 32 128 / 20%), 0 1px 5px 0 rgb(21 32 128 / 15%);
      }
    }
  }
}

</style>
