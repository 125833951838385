import mainApi from "../../plugins/axios/axios";
export default {
  state: {},
  mutations: {},
  actions: {
    getUnits(_,params) {
      return new Promise((resolve, reject) => {
        mainApi
          .get("/admin/units",{params})
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    createUnit(_, newCategory) {
      console.log(newCategory);
      return new Promise((resolve, reject) => {
        mainApi
          .post(`/admin/units`,newCategory)
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    deleteUnit(_,id) {
      return new Promise((resolve, reject) => {
        mainApi
          .delete(`/admin/units/${id}`)
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    editUnit(_, info) {
      console.log(info);
      return new Promise((resolve, reject) => {
        mainApi
          .put(`/admin/units/${info.id}`, info.editedUnit)
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    
    
  },
  getters: {},
};
