<template>
  <div class="spring-spinner">
  <div class="spring-spinner-part top">
    <div class="spring-spinner-rotator"></div>
  </div>
  <div class="spring-spinner-part bottom">
    <div class="spring-spinner-rotator"></div>
  </div>
</div>
</template>

<script>
export default {

}
</script>
<style lang="scss" scoped>
.spring-spinner, .spring-spinner * {
      box-sizing: border-box;
    }

    .spring-spinner {
      width: max-content;
    }

    .spring-spinner .spring-spinner-part {
      overflow: hidden;
      height: calc(60px / 2);
      width: 60px;
      
    }

    .spring-spinner  .spring-spinner-part.bottom {
       transform: rotate(180deg) scale(-1, 1);
      
     }

    .spring-spinner .spring-spinner-rotator {
      width: 60px;
      height: 60px;
      border: calc(60px / 14) solid transparent;
      border-right-color: #ff1d5e;
      border-top-color: #ff1d5e;
      border-radius: 50%;
      box-sizing: border-box;
      animation: spring-spinner-animation 3s ease-in-out infinite;
      transform: rotate(-200deg);
    }

    @keyframes spring-spinner-animation {
      50% {
        transform: rotate(115deg);
      }
    }
</style>