import mainApi from "../../plugins/axios/axios";
export default {
  state: {},
  mutations: {},
  actions: {
    getSizes(_,page) {
      return new Promise((resolve, reject) => {
        mainApi
          .get("/admin/sizes",{params:{page:page}})
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    createSizes(_, newSize) {
      console.log(newSize);
      return new Promise((resolve, reject) => {
        mainApi
          .post(`/admin/sizes`,newSize)
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    deleteSizes(_,id) {
      return new Promise((resolve, reject) => {
        mainApi
          .delete(`/admin/sizes/${id}`)
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    editSizes(_, info) {
      console.log(info);
      return new Promise((resolve, reject) => {
        mainApi
          .put(`/admin/sizes/${info.id}`, {
            width: info.width,
            height: info.height,
            description:info.description
          })
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    
    
  },
  getters: {},
};
