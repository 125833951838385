import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueApexCharts from 'vue-apexcharts'
import vuetify from './plugins/vuetify'
import LoadingSpinner from './components/LoadingSpinner.vue'
import FilterBox from './components/filter/FilterBox.vue'
import './assets/style/main.scss'
Vue.config.productionTip = false
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.component('loading-spinner', LoadingSpinner)
Vue.component('filter-box', FilterBox)
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
