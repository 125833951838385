import mainApi from "../../plugins/axios/axios";
export default {
  state: {},
  mutations: {},
  actions: {
    getLands(_,page) {
      return new Promise((resolve, reject) => {
        mainApi
          .get("/admin/lands",{params:{page:page}})
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    createLands(_, newLand) {
      console.log(newLand);
      return new Promise((resolve, reject) => {
        mainApi
          .post(`/admin/lands`,newLand)
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    deleteLands(_,id) {
      return new Promise((resolve, reject) => {
        mainApi
          .delete(`/admin/lands/${id}`)
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    editLands(_, info) {
      console.log('info:',info);
      return new Promise((resolve, reject) => {
        mainApi
          .put(`/admin/lands/${info.id}`, {
          name: info.name,
          category_id: info.category_id,
          size_id: info.size_id,
          base_price: info.base_price,
          tags: info.tags,
          landscape_id: 1,
          flag: "",
          art_portrait: "",
          art_action: "",
          })
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    getLandTags(_,id) {
      return new Promise((resolve, reject) => {
        mainApi
        .get(`/admin/lands/${id}/tags`)
        .then((result) => {
          resolve(result);
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
      });
    },
    deleteLandTags(_,info) {
      return new Promise((resolve, reject) => {
        mainApi
        .delete(`/admin/lands/${info.routeId}/tags/${info.tagId}`)
        .then((result) => {
          console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    assignTagToLand(_, info) {
      console.log(info);
      return new Promise((resolve, reject) => {
        mainApi
          .post(`/admin/lands/${info.id}/tags`, {tags:info.tags})
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    changeStatus(_, arrayOfItems) {
      return new Promise((resolve, reject) => {
        mainApi
          .post(`/admin/lands/change-status`,{ids:arrayOfItems})
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    addToSale(_, arrayOfItems) {
      return new Promise((resolve, reject) => {
        mainApi
          .post(`/admin/lands/sale`,{ids:arrayOfItems})
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
  },
  getters: {},
};
