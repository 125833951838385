import mainApi from "../../plugins/axios/axios";
export default {
  state: { dailyTotal: null, weeklyTotal: null, monthlyTotal: null },
  mutations: {
    setDailyTotalReport(state, payload) {
      state.dailyTotal = payload;
    },
    setWeeklyTotalReport(state, payload) {
      state.weeklyTotal = payload;
    },
    setMonthlyTotalReport(state, payload) {
      state.monthlyTotal = payload;
    },
  },
  actions: {
    getReportsChartData(_, type) {
      return new Promise((resolve, reject) => {
        mainApi
          .get("/admin/reports/chart", { params: { type: type } })
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    getTotalReportsData(context) {
      mainApi
        .get("/admin/reports/total", { params: { filter: "daily" } })
        .then((result) => {
          console.log(result);
          context.commit("setDailyTotalReport", result.data.result);
        })
        .catch((err) => {
          console.log(err);
        });
      mainApi
        .get("/admin/reports/total", { params: { filter: "weekly" } })
        .then((result) => {
          console.log(result);
          context.commit("setWeeklyTotalReport", result.data.result);
        })
        .catch((err) => {
          console.log(err);
        });
      mainApi
        .get("/admin/reports/total", { params: { filter: "monthly" } })
        .then((result) => {
          console.log(result);
          context.commit("setMonthlyTotalReport", result.data.result);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  getters: {
    dailyTotal(state){
      return state.dailyTotal
    },
    weeklyTotal(state){
      return state.weeklyTotal
    },
    monthlyTotal(state){
      return state.monthlyTotal
    },
  },
};
