<template>
  <v-app>
    <v-main class="">
      <transition name="fade" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>

    <!-- snackbar -->
    <v-snackbar
      v-for="(item, i) in alert"
      :key="i"
      v-model="alert"
      :color="item.type"
      elevation="100"
      centerd
      shaped
      top
      transition="slide-y-transition"
      :type="item.type"
    >
      <div class="d-flex">
        {{ item.message }}
        <v-spacer></v-spacer>
        <v-icon @click="closeAlert">mdi-close</v-icon>
      </div>
    </v-snackbar>
    <!-- snackbar -->
  </v-app>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "App",
  watch: {
    // Change Page
    // newPage() {
    //   if (this.newPage) {
    //     this.$router.push({name : this.newPage})
    //   }
    // }
  },
  computed: {
    ...mapGetters(["alert"]),
  },
  methods:{
    ...mapMutations(['clearAlert']),
    closeAlert(){
      this.clearAlert()
    }
  }
};
</script>
