<template>
  <section class="purchases">
    <v-container>
      <v-row>
        <v-col>
          <main>
            <div
              class="h-500 d-flex align-center justify-center"
              v-if="isLoading"
            >
              <loading-spinner></loading-spinner>
            </div>

            <v-row class="py-5" v-else>
              <v-col cols="12" class="">
                <div
                  v-if="!hasItem"
                  class="h-500 d-flex align-center justify-center"
                >
                  <h3 class="grey--text">There is no data here !!!</h3>
                </div>
                <div v-else-if="WithdrawalsData">
                  <apexchart
                    width="100%"
                    height="450"
                    type="line"
                    :options="options"
                    :series="series"
                  ></apexchart>
                  <h5 class="grey--text text--darken-3 text-center mt-n9">
                    <v-icon color="blue darken-4" small
                      >mdi-information-variant</v-icon
                    >
                    daily information for last 30 days
                  </h5>
                </div>
              </v-col>
            </v-row>
            <v-row
              class="br-12"
              style="background: #88a3e9"
              v-if="monthlyTotal"
            >
              <v-col class="d-flex justify-space-around align-center"
                ><h4 style="color: #020051">Daily total</h4>
                <h2>{{ dailyTotal.withdrawal }}</h2></v-col
              >
              <v-col class="d-flex justify-space-around align-center"
                ><h4 style="color: #020051">Weekly total</h4>
                <h2>{{ weeklyTotal.withdrawal }}</h2></v-col
              >
              <v-col class="d-flex justify-space-around align-center"
                ><h4 style="color: #020051">Monthly Total</h4>
                <h2>{{ monthlyTotal.withdrawal }}</h2></v-col
              >
            </v-row>
          </main>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      WithdrawalsData: null,
      hasItem: false,
      isLoading: false,
      options: {
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [],
        },
      },
      series: [
        {
          name: "Withdraw-Series",
          data: [],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["dailyTotal", "weeklyTotal", "monthlyTotal"]),
  },
  created() {
    this.fetchChartData();
  },
  methods: {
    ...mapActions(["getReportsChartData"]),
    fetchChartData() {
      this.isLoading = true;
      this.getReportsChartData("withdraw")
        .then((res) => {
          if (res.data.length) {
            this.hasItem = true;
            this.WithdrawalsData = res.data;
            console.log(this.WithdrawalsData);
            this.options.xaxis.categories = [];
            this.series.data = [];
            this.WithdrawalsData.forEach((element) => {
              this.options.xaxis.categories.push(element.label);
              this.series[0].data.push(parseInt(element.value));
            });
          } else {
            this.hasItem = false;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.purchases {
}
</style>
