import mainApi from "../../plugins/axios/axios";
export default {
  state: {},
  mutations: {},
  actions: {
    getLotteries() {
      return new Promise((resolve, reject) => {
        mainApi
          .get("/admin/lotteries")
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    createLotteries(_, newContacts) {
      return new Promise((resolve, reject) => {
        mainApi
          .post(`/admin/lotteries`,newContacts)
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    deleteLotteries(_,id) {
      return new Promise((resolve, reject) => {
        mainApi
          .delete(`/admin/lotteries/${id}`)
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    editLotteries(_, info) {
      console.log(info);
      return new Promise((resolve, reject) => {
        mainApi
          .put(`/admin/lotteries/${info.id}`, {
            name: info.name,
            email: info.email,
            body: info.body,
          })
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    
    
  },
  getters: {},
};
