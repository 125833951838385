<template>
  <section class="dashboard pa-3">
    <v-breadcrumbs :items="items" class="bread-crumb-items">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item
          class="bread-crumb-item"
          :disabled="item.disabled"
          :to="item.to"
        >
          {{ item.name }}
        </v-breadcrumbs-item>
      </template>
      <template v-slot:divider>
        <v-icon>mdi-forward</v-icon>
      </template>
    </v-breadcrumbs>
    <loading-spinner class="mx-auto my-16" v-if="isLoading"></loading-spinner>
    <section v-else>
      <v-container>
        <h1 class="mb-4 text-h5">Lands Information</h1>
        <v-row class="py-5">
          <v-col cols="12" sm="6" v-for="(item, i) in lands" :key="i">
            <div class="box d-flex justify-space-between px-5 py-3 white">
              <div
                class="box-icon py-3 px-4 mt-n7 mb-5"
                :style="`background-image: linear-gradient(195deg,${item.color1},${item.color2});`"
              >
                <v-icon large color="white">{{ item.icon }}</v-icon>
              </div>
              <div class="box-info">
                <div class="name">{{ item.name }}</div>
                <div class="data">{{ item.info }}</div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-container>
      <v-container>
        <h1 class="mb-4 text-h5">Category Lands Information</h1>
        <v-row class="py-5 d-flex justify-center">
          <v-col
            cols="12"
            sm="9"
            md="6"
            v-for="(item, i) in categoryLands"
            :key="i"
          >
            <div class="box d-flex justify-space-between px-5 py-3 white">
              <div
                class="box-icon py-3 px-4 mt-n7 mb-5"
                :style="`background-image: linear-gradient(195deg,${item.color1},${item.color2});`"
              >
                <v-icon large color="white">{{ item.icon }}</v-icon>
              </div>
              <div class="box1-info" v-if="item.data">
                <div class="name text-title">Name</div>
                <div class="data">{{ item.data.title }}</div>
              </div>
              <div class="box1-info">
                <div class="name">Total</div>
                <div class="data">{{ item.data.total }}</div>
              </div>
              <div class="box1-info">
                <div class="name">Sale</div>
                <div class="data">{{ item.data.total }}</div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      isLoading: false,
      lands: [
        {
          name: "Total Lands",
          info: null,
          icon: "mdi-island",
          color1: "#ec407a",
          color2: "#d81b60",
        },
        {
          name: "Total Lands Sales",
          info: null,
          icon: "mdi-sale",
          color1: "#42424a",
          color2: "#191919",
        },
      ],
      categoryLands: [
        {
          data: {},
          icon: "mdi-order-bool-descending-variant",
          color1: "#66bb6a",
          color2: "#43a047",
        },
        {
          data: {},
          icon: "mdi-order-bool-descending-variant",
          color1: "#49a3f1",
          color2: "#1a73e8",
        },
        {
          data: {},
          icon: "mdi-order-bool-descending-variant",
          color1: "#ffa726",
          color2: "#fb8c00",
        },
        {
          data: {},
          icon: "mdi-order-bool-descending-variant",
          color1: "#f55cff",
          color2: "#00c7be",
        },
        {
          data: {},
          icon: "mdi-order-bool-descending-variant",
          color1: "#747b8a",
          color2: "#495361",
        },
        {
          data: {},
          icon: "mdi-order-bool-descending-variant",
          color1: "#bc00c9",
          color2: "#a406c1",
        },
        {
          data: {},
          icon: "mdi-order-bool-descending-variant",
          color1: "#24ffd7",
          color2: "#00c7be",
        },
        {
          data: {},
          icon: "mdi-order-bool-descending-variant",
          color1: "#ef5350",
          color2: "#e53935",
        },
        {
          data: {},
          icon: "mdi-order-bool-descending-variant",
          color1: "yellow",
          color2: "#e53935",
        },
        {
          data: {},
          icon: "mdi-order-bool-descending-variant",
          color1: "purple",
          color2: "brown",
        },
      ],
      items: [
        {
          name: "Dashboard",
          disabled: true,
          to: "/dashboard",
        },
      ],
    };
  },
  computed: {},
  created() {
    this.fetchTotalLands();
    this.fetchTotalCategoryLands();
  },
  methods: {
    ...mapActions(["getTotalLands", "getTotalCategoryLands"]),

    fetchTotalLands() {
      this.isLoading = true;
      this.getTotalLands()
        .then((result) => {
          console.log(result.data.result);
          this.lands[0].info = result.data.result.total_lands;
          this.lands[1].info = result.data.result.total_lands_sales;
        })
        .catch(() => {
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    fetchTotalCategoryLands() {
      this.isLoading = true;
      this.getTotalCategoryLands()
        .then((result) => {
          console.log(result.data.result[0]);
          for (let i = 0; i < this.categoryLands.length; i++) {
            this.categoryLands[i].data = result.data.result[i];
          }
        })
        .catch(() => {
          this.isLoading = false;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/style/colors.scss";
.dashboard {
  .box {
    border-radius: 10px;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%);
    .box-icon {
      border-radius: 12px;
      box-shadow: 5px 8px 6px -1px rgb(0 0 0 / 10%),
        0 5px 6px -1px rgb(0 0 0 / 6%);
    }
    .box-info {
      min-width: 136px;
      .name {
        color: darken($grey, 5%);
        font-size: 15px;
        font-weight: 500;
      }
      .data {
        color: $deep-blue;
        font-size: 18px;
        font-weight: 900;
      }
    }
    .box1-info {
      min-width: 86px;
      // background: rgb(153, 153, 227);
      @media only screen and(max-width: 400px) {
        min-width: 46px;
      }
      .name {
        color: darken($grey, 5%);
        font-size: 12px;
        font-weight: 500;
        text-align: center;
        @media only screen and(max-width: 400px) {
          font-size: 10px;
        }
      }
      .data {
        color: $deep-blue;
        font-size: 15px;
        font-weight: 900;
        text-align: center;
        @media only screen and(max-width: 400px) {
          font-size: 13px;
        }
      }
    }
  }
}
</style>
