<template>
  <section class="lotteries pa-3">
    <section class="d-flex justify-space-between">
      <v-breadcrumbs :items="items" divider="/" class="bread-crumb-items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            class="bread-crumb-item"
            :disabled="item.disabled"
            :to="item.to"
          >
            {{ item.name }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="back-button mr-5"
            outlined
            fab
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="$router.go(-1)"
          >
            <v-icon>mdi-undo-variant</v-icon>
          </v-btn>
        </template>
        <span>Go Back</span>
      </v-tooltip>
    </section>

    <v-card>
      <div class="table-title d-flex">
        <v-icon color="white" class="mr-3">mdi-strategy</v-icon>
        <h1>Lotteries List</h1>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="add-button"
              dark
              fab
              small
              color="white"
              v-bind="attrs"
              v-on="on"
              @click="addDialog = true"
            >
              <v-icon dark color="black"> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Create Lottery</span>
        </v-tooltip>
      </div>
      <v-data-table
        :headers="headers"
        :items="localLotteries"
        :loading="isLoading"
        :items-per-page="15"
        hide-default-footer
        class="table elevation-1"
      >
        <template v-slot:item.created_at="{ item }">{{
          formatDate(item.created_at)
        }}</template>
        <template v-slot:item.updated_at="{ item }">{{
          formatDate(item.updated_at)
        }}</template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="showEdit(item)">
                <v-icon color="green" small class="icon">mdi-pen</v-icon>
              </button>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="submitDelete(item)">
                <v-icon color="red" small class="icon">mdi-delete</v-icon>
              </button>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <div class="table-paginate-box">
      <div class="table-pagination-total">
        {{ total }} <span class="">result(s)</span>
      </div>
      <div class="paginate-box">
        <v-pagination
          v-model="page"
          class="pagination"
          :length="lastPage"
          :total-visible="3"
          @input="fetchLotteries()"
          color="blue"
          circle
        ></v-pagination>
      </div>
    </div>
    <!-- add Lotteries dialog -->
    <v-dialog v-model="addDialog" width="500">
      <v-card class="card">
        <v-card-title class="card-title">
          <h5>Add New Lottery</h5>
        </v-card-title>

        <v-card-text class="mt-2">
          <v-form class="form" v-model="valid" ref="form">
            <p class="my-2 grey--text">
              Please enter the Lottery information below.
            </p>
            <v-text-field
              rounded
              outlined
              dense
              label="Title"
              v-model="title"
              :rules="[rules.required, rules.maxLength, rules.minLength]"
            ></v-text-field>
            <v-text-field
              rounded
              outlined
              dense
              label="Icon"
              v-model="icon"
            ></v-text-field>
            <v-textarea
              outlined
              dense
              rows="3"
              label="Description"
              v-model="description"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="submitAdd"
            :loading="isLoading"
            :disabled="!valid"
          >
            Add Lottery
          </v-btn>
          <v-btn color="error" text @click="addDialog = false"> Cancle </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add Lotteries dialog -->

    <!-- edit Lotteries dialog -->
    <v-dialog v-model="editDialog" width="500">
      <v-card class="card">
        <v-card-title class="card-title">
          <h5>Edit Lottery</h5>
        </v-card-title>

        <v-card-text class="mt-2 card_text">
          <v-form class="form" v-model="valid">
            <p class="my-2 grey--text">
              Please enter the Lottery information below.
            </p>
            <v-text-field
              rounded
              outlined
              dense
              label="Title"
              v-model="editTitle"
              :rules="[rules.required, rules.maxLength, rules.minLength]"
            ></v-text-field>
            <!-- <v-text-field
              rounded
              outlined
              dense
              label="Email"
              v-model="editEmail"
              :rules="[rules.emailRules]"
            ></v-text-field>
            <v-textarea
              outlined
              dense
              rows="3"
              label="Body"
              v-model="editBody"
            ></v-textarea> -->
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="submitEdit" :loading="isLoading">
            Update Lottery
          </v-btn>
          <v-btn color="error" text @click="editDialog = false"> Cancle </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit Lotteries dialog -->

  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      page: 1,
      lastPage: 0,
      total: 0,
      itemSelected: {},
      localLotteries: [],
      // addDilaog
      addDialog: false,
      title: "",
      icon: "",
      description: "",
      
      valid: true,
      // addDilaog

      //editDialog
      editDialog: false,
      editTitle: "",
      //editDialog

      items: [
        {
          name: "Lotteries",
          disabled: true,
          to: "/home/lotteries",
        },
      ],
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Email",
          value: "email",
        },
        { text: "Body", value: "body" },
        { text: "Created at", value: "created_at" },
        { text: "Updated at", value: "updated_at" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  computed: {
    ...mapGetters(["errorMessage","rules"]),
  },
  created() {
    this.fetchLotteries();
  },
  methods: {
    ...mapActions([
      "getLotteries",
      "createLotteries",
      "deleteLotteries",
      "editLotteries",
    ]),
    fetchLotteries() {
      this.isLoading = true;
      this.getLotteries()
        .then((result) => {
          console.log(result);
          this.localLotteries = result.data.data;
          this.total = result.data.total;
          this.lastPage = result.data.last_page;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    submitAdd() {
      if (this.$refs.form.validate()) {
        let newLottery = {
          title: this.title,
          icon: this.icon,
          description: this.description,
        };
        this.isLoading = true;
        this.createLotteries(newLottery)
          .then(() => {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.addDialog = false;
            this.fetchLotteries();
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    submitDelete(item) {
      this.isLoading = true;
      this.deleteLotteries(item.id)
        .then(() => {
          this.fetchLotteries();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showEdit(item) {
      this.itemSelected = item;
      this.editDialog = true;
      this.editTitle = item.title;
    },
    submitEdit() {
      let info = {
        id: this.itemSelected.id,
        title: this.editTilte,
      };
      this.isLoading = true;
      this.editLotteries(info)
        .then(() => {
          this.fetchLotteries();
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
          this.editDialog = false;
        });
    },
    formatDate(date) {
      let newDate = new Date(date);
      return newDate.toLocaleString();
    },
  },
};
</script>

<style lang="scss" scoped>
.lotteries {
  .add-button {
    position: absolute;
    right: 20px;
    right: 30px;
  }
  .table {
    padding-top: 45px;
    .v-icon {
      margin-right: 10px;
    }
  }
  ::v-deep .v-data-table .v-data-table__wrapper table {
    width: 100%;
    min-width: 930px;
  }
}
.card_text {
  height: auto !important;
  overflow: auto;
}
</style>
