export default {
  state: {
    rules: {
      required: (v) => !!v || "This Field is Required",
      maxLength: (v) =>
        v == null ||
        v.length <= 50 ||
        "This field must be less than 50 characters",
      minLength: (v) =>
        v == null ||
        v.length >= 3 ||
        "This field must be more than 3 characters",
      emailRules: (v) => /.+@.+/.test(v) || "E-mail must be valid",
      multiSelect: (v) => v.length > 0 || "This Field is Required",
    },
  },
  mutations: {},
  actions: {},
  getters: {
    rules(state) {
      return state.rules;
    },
  },
};
