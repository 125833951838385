import mainApi from "../../plugins/axios/axios";
export default {
  state: {},
  mutations: {},
  actions: {
    getConfigs(_, page) {
      return new Promise((resolve, reject) => {
        mainApi
          .get("/admin/configs", { params: { page: page } })
          .then((result) => {
            resolve(result.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    editConfig(_, info) {
      return new Promise((resolve, reject) => {
        mainApi
          .patch(`/admin/configs/${info.id}`, {
            value: info.value,
          })
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
  },
  getters: {},
};
