<template>
  <nav>
    <section
      class="toolbar w-100 px-1 d-flex justify-space-between align-center"
    >
      <div class="d-flex align-center">
        <button
          class="menu-icon pa-4 ma-2"
          @click.stop="drawer = !drawer"
          color="grey darken-2"
        >
          <v-icon>mdi-format-list-bulleted-square</v-icon>
        </button>
        <h1 class="text-uppercase font-weight-light mr-1">{{firstWordSiteTitle}}</h1>
        <h1 class="text-uppercase font-weight-bold blue--text">{{secondWordSiteTitle}}</h1>
      </div>
      <div class="d-flex align-center">
        <div class="d-flex mr-3">
          <v-icon color="deep-purple">mdi-account</v-icon>
          <p class="grey--text">{{ localUser.name }}</p>
        </div>
        <div class="d-flex">
          <v-icon color="blue">mdi-gmail</v-icon>
          <p class="grey--text">{{ localUser.email }}</p>
        </div>
        <v-btn text @click="confirmLogout"
          >Logout <v-icon small right>mdi-logout</v-icon></v-btn
        >
      </div>
    </section>
    <v-navigation-drawer
      v-model="drawer"
      width="270px"
      app
      hide-overlay
      class="pa-4"
      color="
    transparent"
    >
      <section class="toolbar-content br-12 d-flex flex-column">
        <div class="logo d-flex align-center pa-3 justify-center">
          <v-img
            src="@/assets/image/white-logo.png"
            max-width="65"
            max-height="45"
          ></v-img>
          <h1 class="text-h6">AZOLITE</h1>
        </div>
        <v-divider class="grey darken-2"></v-divider>
        <div class="items py-2">
          <router-link :to="{name: 'dashboard'}" class="item d-flex px-3 py-3 ma-2">
            <v-icon class="white--text mr-2" size="18"
              >mdi-view-dashboard</v-icon
            >
            <h4 class="list-title">dashboard</h4>
          </router-link>
          <router-link :to="{name: 'reports'}" class="item d-flex px-3 py-3 ma-2">
            <v-icon class="white--text mr-2" size="18"
              >mdi-chart-line-stacked</v-icon
            >
            <h4 class="list-title">Reports</h4>
          </router-link>

          <!-- list group -->
          <section class="toolbar-list-group">
            <section class="header d-flex mt-2 py-2 px-4" @click="show = !show">
              <h4>Menu</h4>
              <v-spacer></v-spacer>
              <v-icon
                color="white"
                :style="`${show ? 'transform: rotate(-180deg);' : ''}`"
                >mdi-chevron-down</v-icon
              >
            </section>
            <section class="list-items" :class="{ show: show }">
              <router-link
                class="list-item d-flex align-center px-3 py-2 my-2 mx-3"
                :class="{ show: show }"
                :to="{name: 'lands'}"
              >
                <v-icon color="white" class="mr-3">mdi-island</v-icon>
                <h4 class="list-title">Lands</h4>
              </router-link>
              <router-link
                class="list-item d-flex align-center px-3 py-2 my-2 mx-3"
                :class="{ show: show }"
                :to="{name: 'lotteries'}"
              >
                <v-icon color="white" class="mr-3">mdi-drawing-box</v-icon>
                <h4 class="list-title">Lotteries</h4>
              </router-link>
              <router-link
                class="list-item d-flex align-center px-3 py-2 my-2 mx-3"
                :class="{ show: show }"
                :to="{name: 'resource'}"
              >
                <v-icon color="white" class="mr-3">mdi-semantic-web</v-icon>
                <h4 class="list-title">Resource</h4>
              </router-link>
              <router-link
                class="list-item d-flex align-center px-3 py-2 my-2 mx-3"
                :class="{ show: show }"
                :to="{name: 'tags'}"
              >
                <v-icon color="white" class="mr-3">mdi-tag</v-icon>
                <h4 class="list-title">Tags</h4>
              </router-link>
              <router-link
                class="list-item d-flex align-center px-3 py-2 my-2 mx-3"
                :class="{ show: show }"
                :to="{name: 'configs'}"
              >
                <v-icon color="white" class="mr-3">mdi-cog</v-icon>
                <h4 class="list-title">Configs</h4>
              </router-link>

              <router-link
                class="list-item d-flex align-center px-3 py-2 my-2 mx-3"
                :class="{ show: show }"
                :to="{name: 'categories'}"
              >
                <v-icon color="white" class="mr-3"
                  >mdi-order-bool-descending-variant</v-icon
                >
                <h4 class="list-title">Categories</h4>
              </router-link>
              <router-link
                class="list-item d-flex align-center px-3 py-2 my-2 mx-3"
                :class="{ show: show }"
                :to="{name: 'sizes'}"
              >
                <v-icon color="white" class="mr-3"
                  >mdi-crop</v-icon
                >
                <h4 class="list-title">Sizes</h4>
              </router-link>
              <router-link
                class="list-item d-flex align-center px-3 py-2 my-2 mx-3"
                :class="{ show: show }"
                :to="{name: 'contact'}"
              >
                <v-icon color="white" class="mr-3">mdi-card-account-phone</v-icon>
                <h4 class="list-title">Contact Us</h4>
              </router-link>
              <router-link
                class="list-item d-flex align-center px-3 py-2 my-2 mx-3"
                :class="{ show: show }"
                :to="{name: 'buildings'}"
              >
                <v-icon color="white" class="mr-3">mdi-city-variant</v-icon>
                <h4 class="list-title">Buildings</h4>
              </router-link>
              <router-link
                class="list-item d-flex align-center px-3 py-2 my-2 mx-3"
                :class="{ show: show }"
                :to="{name: 'units'}"
              >
                <v-icon color="white" class="mr-3">mdi-unity</v-icon>
                <h4 class="list-title">Units</h4>
              </router-link>
            </section>
          </section>
          <!-- list group -->
        </div>
        <button class="logout mt-auto mx-auto mb-5" @click="confirmLogout">
          Logout <v-icon color="white" size="18" right>mdi-logout</v-icon>
        </button>
      </section>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      drawer: true,
      show: false,
      localUser: "",
    };
  },
  computed: {
    ...mapGetters(["user"]),

    firstWordSiteTitle() {
      return  process.env.VUE_APP_SITE_LOGO_NAME.substring(0, 8);
    },
    secondWordSiteTitle() {
      return  process.env.VUE_APP_SITE_LOGO_NAME.substring(9, 15);
    }
  },
  mounted() {
    if (typeof this.user === "object") {
      this.localUser = this.user;
    } else {
      this.localUser = JSON.parse(this.user);
    }
  },
  methods: {
    ...mapActions(["logout"]),
    confirmLogout() {
      this.logout();
      this.$router.replace("/login");
    },
  },
};
</script>

<style lang="scss" scoped></style>
