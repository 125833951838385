import mainApi from "../../plugins/axios/axios";
export default {
  state: {},
  mutations: {},
  actions: {
    getBuildings(_,params) {
      return new Promise((resolve, reject) => {
        mainApi
          .get("/admin/buildings",{params})
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    createBuildings(_, newItem) {
      return new Promise((resolve, reject) => {
        mainApi
          .post(`/admin/buildings`,newItem)
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    deleteBuildings(_,id) {
      return new Promise((resolve, reject) => {
        mainApi
          .delete(`/admin/buildings/${id}`)
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    editBuildings(_, info) {
      console.log(info);
      return new Promise((resolve, reject) => {
        mainApi
          .put(`/admin/buildings/${info.id}`, info.editedBuilding)
          .then((result) => {
            console.log(result);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject();
          });
      });
    },
    
    
  },
  getters: {},
};
