<template>
  <section class="lands pa-3">
    <section class="d-flex justify-space-between">
      <v-breadcrumbs :items="items" divider="/" class="bread-crumb-items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            class="bread-crumb-item"
            :disabled="item.disabled"
            :to="item.to"
          >
            {{ item.name }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="back-button mr-5"
            outlined
            fab
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="$router.go(-1)"
          >
            <v-icon>mdi-undo-variant</v-icon>
          </v-btn>
        </template>
        <span>Go Back</span>
      </v-tooltip>
    </section>

    <v-card>
      <div class="table-title d-flex">
        <v-icon color="white" class="mr-3">mdi-strategy</v-icon>
        <h1>Lands List</h1>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="add-button"
              dark
              fab
              small
              color="white"
              v-bind="attrs"
              v-on="on"
              @click="showAddDialog"
            >
              <v-icon dark color="black"> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Create Land</span>
        </v-tooltip>
      </div>
      <v-data-table
        v-model="selectedItems"
        :headers="headers"
        :items="localLands"
        :loading="isLoading"
        :items-per-page="15"
        show-select
        hide-default-footer
        class="table elevation-1"
      >
        <template v-slot:item.base_price="{ item }">
          <div v-if="typeof item.base_price === 'number'">
            {{ item.base_price }}
          </div>
          <div v-else>
            <span
              v-for="(value, key) in item.base_price"
              :key="key"
              class="price-item"
            >
              {{ key }} : {{ value.price }}
            </span>
          </div>
        </template>
        <template v-slot:top>
          <div class="d-flex" style="min-height: 35px">
            <v-btn
              outlined
              style="font-size: 12px"
              color="pink"
              class="ml-auto"
              v-if="selectedItems.length >= 1"
              @click="exchangeStatus(selectedItems)"
            >
              <v-icon color="pink" small left
                >mdi-swap-horizontal-circle-outline</v-icon
              >Change Group Stauts</v-btn
            >
            <v-btn
              outlined
              style="font-size: 12px"
              color="balck"
              class="mx-2"
              v-if="selectedItems.length >= 1"
              @click="sendToSale(selectedItems)"
            >
              <v-icon color="black" small left>mdi-cart-arrow-up</v-icon>Add
              Group To Sale</v-btn
            >
          </div>
        </template>
        <template v-slot:item.status="{ item }">
          <div class="d-flex align-center">
            <div
              style="width: 6px; height: 6px; border-radius: 50%"
              :style="
                item.status === 'disable'
                  ? 'background:red'
                  : 'background:green'
              "
              class="mr-1"
            ></div>
            <span
              :class="item.status === 'disable' ? 'red--text' : 'green--text'"
              >{{ item.status }}</span
            >
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="showDetail(item)">
                <v-icon color="orange darken-1" small class="icon"
                  >mdi-information</v-icon
                >
              </button>
            </template>
            <span>Show Details</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="showEdit(item)">
                <v-icon color="green" small class="icon">mdi-pen</v-icon>
              </button>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="showTags(item)">
                <v-icon color="purple" small class="icon"
                  >mdi-tag-multiple</v-icon
                >
              </button>
            </template>
            <span>Show Tags</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="showAssignDialog(item)">
                <v-icon color="blue" small class="icon"
                  >mdi-tag-plus-outline</v-icon
                >
              </button>
            </template>
            <span>Assign Tag</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="sendToSale(item)">
                <v-icon color="black" small class="icon"
                  >mdi-cart-arrow-up</v-icon
                >
              </button>
            </template>
            <span>Add to Sale</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="exchangeStatus(item)">
                <v-icon color="pink" small class="icon"
                  >mdi-swap-horizontal-circle-outline</v-icon
                >
              </button>
            </template>
            <span>Change Status</span>
          </v-tooltip>
          <!-- <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="submitDelete(item)">
                <v-icon color="red" small class="icon">mdi-delete</v-icon>
              </button>
            </template>
            <span>Delete</span>
          </v-tooltip> -->
        </template>
      </v-data-table>
    </v-card>
    <div class="table-paginate-box">
      <div class="table-pagination-total">
        {{ total }} <span class="">result(s)</span>
      </div>
      <div class="paginate-box">
        <v-pagination
          v-model="page"
          class="pagination"
          :length="lastPage"
          :total-visible="5"
          @input="fetchLands()"
          color="blue"
          circle
        ></v-pagination>
      </div>
    </div>
    <!--detail dialog -->
    <v-dialog v-model="detailDialog" width="800">
      <v-card class="card">
        <v-card-title class="card-title">
          <section class="w-100 d-flex justify-space-between align-center">
            <h5>Show Land Details</h5>
            <div class="d-flex">
              <h3 class="mr-3">
                ID: <span class="white--text">{{ itemSelected.id }}</span>
              </h3>
            </div>
          </section>
        </v-card-title>

        <v-card-text class="mt-2 card-text">
          <section class="my-5 d-flex">
            <h1 class="mr-2">Name:</h1>
            <h3>
              {{ itemSelected.name }}
            </h3>
          </section>
          <section class="my-5 d-flex">
            <h1 class="mr-2">Size:</h1>
            <h3>
              {{ itemSelected.size }}
            </h3>
          </section>
          <section class="my-5 d-flex">
            <h1 class="mr-2">Area:</h1>
            <h3>
              {{ itemSelected.area }}
            </h3>
          </section>
          <section class="my-5 d-flex">
            <h1 class="mr-2">width:</h1>
            <h3>
              {{ itemSelected.width }}
            </h3>
          </section>
          <section class="my-5 d-flex">
            <h1 class="mr-2">Height:</h1>
            <h3>
              {{ itemSelected.height }}
            </h3>
          </section>
          <section class="my-5 d-flex">
            <h1 class="mr-2">CoordinateX:</h1>
            <h3>
              {{ itemSelected.coordinateX }}
            </h3>
          </section>
          <section class="my-5 d-flex">
            <h1 class="mr-2">CoordinateY:</h1>
            <h3>
              {{ itemSelected.coordinateY }}
            </h3>
          </section>
          <section class="my-5 d-flex">
            <h1 class="mr-2">Price:</h1>
            <h3>
              {{ itemSelected.price }}
            </h3>
          </section>
          <section class="my-5 d-flex">
            <h1 class="mr-2">Price Description:</h1>
            <h3 v-html="itemSelected.priceDescription"></h3>
          </section>
          <section class="my-5 d-flex">
            <h1 class="mr-2">Category:</h1>
            <h3>
              {{ itemSelected.category }}
            </h3>
          </section>
          <section class="my-5 d-flex">
            <h1 class="mr-2">Parent Category:</h1>
            <h3>
              {{ itemSelected.parentCategory }}
            </h3>
          </section>
          <section class="my-5 d-flex">
            <h1 class="mr-2">Updated at:</h1>
            <h3>
              {{ formatDate(itemSelected.updated_at) }}
            </h3>
          </section>
          <section class="my-5 d-flex">
            <h1 class="mr-2">art_portrait:</h1>
            <h3>
              {{ itemSelected.art_portrait }}
            </h3>
          </section>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="detailDialog = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--detail dialog -->
    <!-- add Land dialog -->
    <v-dialog v-model="addDialog" width="500">
      <v-card class="card">
        <v-card-title class="card-title">
          <h5>Add New Land</h5>
        </v-card-title>

        <v-card-text class="mt-2">
          <v-form class="form" v-model="valid" ref="form">
            <p class="my-2 grey--text">
              Please enter the Land information below.
            </p>
            <v-text-field
              rounded
              outlined
              dense
              label="Name"
              v-model="name"
              :rules="[rules.required, rules.maxLength, rules.minLength]"
            ></v-text-field>
            <v-text-field
              rounded
              outlined
              dense
              type="number"
              label="Category id"
              :rules="[rules.required]"
              v-model="category_id"
            ></v-text-field>
            <v-select
              :items="sizes"
              v-model="selectedSize"
              item-text="title"
              item-value="id"
              label="Size List"
              dense
              outlined
              clearable
              class="my-3"
              :rules="[rules.required]"
              :menu-props="{ bottom: true, offsetY: true }"
            ></v-select>

            <v-text-field
              rounded
              outlined
              dense
              type="number"
              label="Price"
              :rules="[rules.required]"
              v-model="base_price"
            ></v-text-field>
            <v-select
              :items="tags"
              v-model="selectedTags"
              item-text="title"
              item-value="id"
              label="Tags List"
              dense
              outlined
              multiple
              small-chips
              deletable-chips
              clearable
              class="my-3"
              :rules="[rules.multiSelect]"
              :menu-props="{ bottom: true, offsetY: true }"
            ></v-select>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="submitAdd"
            :loading="isLoading"
            :disabled="!valid"
          >
            Add Land
          </v-btn>
          <v-btn color="error" text @click="addDialog = false"> Cancle </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add Land dialog -->

    <!-- edit Land dialog -->
    <v-dialog v-model="editDialog" width="500">
      <v-card class="card">
        <v-card-title class="card-title">
          <h5>Edit Land</h5>
        </v-card-title>

        <v-card-text class="mt-2 card_text">
          <v-form class="form" v-model="valid">
            <p class="my-2 grey--text">
              Please enter the Land information below.
            </p>
            <v-text-field
              rounded
              outlined
              dense
              label="Name"
              v-model="editName"
              :rules="[rules.required, rules.maxLength, rules.minLength]"
            ></v-text-field>
            <v-text-field
              rounded
              outlined
              dense
              type="number"
              label="Category Id"
              v-model="editCategory_id"
            ></v-text-field>
            <v-select
              :items="sizes"
              v-model="editSize_id"
              item-text="title"
              item-value="id"
              label="Size List"
              dense
              outlined
              clearable
              class="my-3"
              :rules="[rules.required]"
              :menu-props="{ bottom: true, offsetY: true }"
            ></v-select>

            <v-text-field
              rounded
              outlined
              dense
              type="number"
              label="Price"
              :rules="[rules.required]"
              v-model="editBase_price"
            ></v-text-field>
            <v-select
              :items="tags"
              v-model="editTags"
              item-text="title"
              item-value="id"
              label="Tags List"
              dense
              outlined
              multiple
              small-chips
              deletable-chips
              clearable
              class="my-3"
              :rules="[rules.multiSelect]"
              :menu-props="{ bottom: true, offsetY: true }"
              :loading="isLoading"
            ></v-select>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="submitEdit" :loading="isLoading">
            Update Land
          </v-btn>
          <v-btn color="error" text @click="editDialog = false"> Cancle </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit Land dialog -->

    <!-- assign tag dialog -->
    <v-dialog v-model="assignDialog" width="500">
      <v-card class="card">
        <v-card-title class="card-title">
          <h5>Assing Tag To Land</h5>
        </v-card-title>

        <v-card-text class="mt-2">
          <v-form class="form" v-model="valid" ref="form">
            <p class="my-2 grey--text">
              Please enter the Land information below.
            </p>
            <v-select
              :items="tags"
              v-model="selectedTags"
              item-text="title"
              item-value="id"
              label="Tags"
              :loading="isLoading"
              dense
              outlined
              multiple
              small-chips
              deletable-chips
              clearable
              class="my-3"
              :menu-props="{ bottom: true, offsetY: true }"
              :rules="[rules.multiSelect]"
            ></v-select>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="submitAssign"
            :loading="isLoading"
            :disabled="!valid"
          >
            Assign Tags
          </v-btn>
          <v-btn color="error" text @click="assignDialog = false">
            Cancle
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add Land dialog -->
  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      detailDialog: false,
      page: 1,
      lastPage: 0,
      total: 0,
      itemSelected: {},
      localLands: [],
      // addDilaog
      addDialog: false,
      name: "",
      category_id: null,
      size_id: null,
      landscape_id: null,
      base_price: null,
      sizes: [],
      selectedSize: null,
      tags: [],
      selectedTags: [],
      valid: true,
      // addDilaog

      //editDialog
      editDialog: false,
      editName: null,
      editCategory_id: null,
      editSize_id: null,
      editBase_price: null,
      editTags: [],
      editedIdTags: [],
      //editDialog

      // assign dialog
      assignDialog: false,
      addedTags: [],
      // assign dialog

      items: [
        {
          name: "Lands",
          disabled: true,
          to: "/home/Lands",
        },
      ],
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Name",
          value: "name",
        },
        {
          text: "Area",
          value: "area",
        },
        { text: "Width", value: "width" },
        { text: "Height", value: "height" },
        { text: "Size", value: "size" },
        { text: "Price", value: "base_price" },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions" },
      ],
      selectedItems: [],
    };
  },
  computed: {
    ...mapGetters(["rules"]),
  },
  created() {
    this.fetchLands();
  },
  methods: {
    ...mapActions([
      "getLands",
      "createLands",
      "deleteLands",
      "editLands",
      "getTags",
      "getLandTags",
      "assignTagToLand",
      "getSizes",
      "changeStatus",
      "addToSale",
    ]),
    fetchLands() {
      this.isLoading = true;
      this.getLands(this.page)
        .then((result) => {
          console.log(result);
          this.localLands = result.data.data;
          this.total = result.data.meta.total;
          this.lastPage = result.data.meta.last_page;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showDetail(item) {
      this.detailDialog = true;
      this.itemSelected = item;
    },
    showAddDialog() {
      this.addDialog = true;
      this.getTags().then((res) => {
        this.tags = res.data.result;
      });
      this.getSizes().then((res) => {
        this.sizes = res.data.data;
      });
    },
    submitAdd() {
      if (this.$refs.form.validate()) {
        let newLand = {
          name: this.name,
          category_id: this.category_id,
          size_id: this.selectedSize,
          base_price: this.base_price,
          tags: this.selectedTags,
          landscape_id: 1,
          flag: "",
          art_portrait: "",
          art_action: "",
        };
        console.log(newLand);
        this.isLoading = true;
        this.createLands(newLand)
          .then(() => {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.addDialog = false;
            this.fetchLands();
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    // submitDelete(item) {
    //   this.isLoading = true;
    //   this.deleteLands(item.id)
    //     .then(() => {
    //       this.fetchLands();
    //     })
    //     .finally(() => {
    //       this.isLoading = false;
    //     });
    // },
    showEdit(item) {
      this.isLoading = true;
      this.getTags().then((res) => {
        this.tags = res.data.result;
      });
      this.getSizes().then((res) => {
        this.sizes = res.data.data;
        this.isLoading = false;
      });
      this.itemSelected = item;
      this.editDialog = true;
      this.editName = item.name;
      this.editCategory_id = item.category_id;
      this.editSize_id = item.size_id;
      this.editBase_price = item.base_price;
      this.editTags = item.tags;
    },
    submitEdit() {
      this.editTags.forEach((element) => {
        this.editedIdTags.push(element.id);
      });
      let info = {
        id: this.itemSelected.id,
        name: this.editName,
        category_id: this.editCategory_id,
        size_id: this.editSize_id,
        base_price: this.editBase_price,
        tags: this.editedIdTags,
        landscape_id: 1,
        flag: "",
        art_portrait: "",
        art_action: "",
      };
      this.isLoading = true;
      this.editLands(info)
        .then(() => {
          this.fetchLands();
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
          this.editDialog = false;
          this.editedIdTags = [];
        });
    },
    formatDate(date) {
      let newDate = new Date(date);
      return newDate.toLocaleString();
    },
    showTags(item) {
      this.$router.push(`/lands/${item.id}`);
    },
    showAssignDialog(item) {
      this.assignDialog = true;
      this.itemSelected = item;
      this.getTags().then((res) => {
        this.tags = res.data.result;
      });
      this.getLandTags(item.id).then((result) => {
        this.selectedTags = result.data.result;
      });
    },
    submitAssign() {
      if (this.$refs.form.validate()) {
        this.selectedTags.forEach((element) => {
          if (typeof element === "object") {
            this.addedTags.push(element.id);
          } else {
            this.addedTags.push(element);
          }
        });
        let info = {
          id: this.itemSelected.id,
          tags: this.addedTags,
        };
        this.isLoading = true;
        this.assignTagToLand(info)
          .then(() => {
            this.fetchLands();
          })
          .finally(() => {
            this.isLoading = false;
            this.assignDialog = false;
            this.addedTags = [];
          });
      }
    },
    exchangeStatus(items) {
      console.log(Array.isArray(items));
      let ids = [];
      if (Array.isArray(items)) {
        items.forEach((element) => {
          ids.push(element.id);
        });
      } else {
        ids.push(items.id);
      }
      this.changeStatus(ids)
        .then((result) => {
          console.log(result);
          this.fetchLands();
        })
        .finally(() => {
          ids = [];
        });
    },
    sendToSale(items) {
      let ids = [];
      if (Array.isArray(items)) {
        items.forEach((element) => {
          ids.push(element.id);
        });
      } else {
        ids.push(items.id);
      }
      this.addToSale(ids)
        .then((result) => {
          console.log(result);
          this.fetchLands();
        })
        .finally(() => {
          ids = [];
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.lands {
  .add-button {
    position: absolute;
    right: 20px;
    right: 30px;
  }
  .table {
    padding-top: 45px;
    .v-icon {
      margin-right: 10px;
    }
  }
  ::v-deep .v-data-table .v-data-table__wrapper table {
    width: 100%;
    min-width: 930px;
  }
}

td {
  .price-item {
    &::after {
      content: ",";
    }
    &:last-child {
      &::after {
        content: "";
      }
    }
  }
}
.card-text {
  height: 500px !important;
  overflow: auto;
}
</style>
