<template>
  <section class="tags pa-3">
    <section class="d-flex justify-space-between">
      <v-breadcrumbs :items="items" divider="/" class="bread-crumb-items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            class="bread-crumb-item"
            :disabled="item.disabled"
            :to="item.to"
          >
            {{ item.name }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="back-button mr-5"
            outlined
            fab
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="$router.go(-1)"
          >
            <v-icon>mdi-undo-variant</v-icon>
          </v-btn>
        </template>
        <span>Go Back</span>
      </v-tooltip>
    </section>

    <v-card>
      <div class="table-title d-flex">
        <v-icon color="white" class="mr-3">mdi-strategy</v-icon>
        <h1>Tags List</h1>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="add-button"
              dark
              fab
              small
              color="white"
              v-bind="attrs"
              v-on="on"
              @click="addDialog = true"
            >
              <v-icon dark color="black"> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Create Tag</span>
        </v-tooltip>
      </div>
      <v-data-table
        :headers="headers"
        :items="localTags"
        :loading="isLoading"
        :items-per-page="15"
        hide-default-footer
        class="table elevation-1"
      >
        <template v-slot:item.created_at="{ item }">{{
          formatDate(item.created_at)
        }}</template>
        <template v-slot:item.updated_at="{ item }">{{
          formatDate(item.updated_at)
        }}</template>
        <template v-slot:item.description="{ item }">{{
          item.description != null ? item.description : "--"
        }}</template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="showEdit(item)">
                <v-icon color="green" small class="icon">mdi-pen</v-icon>
              </button>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="submitDelete(item)">
                <v-icon color="red" small class="icon">mdi-delete</v-icon>
              </button>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <div class="table-paginate-box">
      <div class="table-pagination-total">
        {{ total }} <span class="">result(s)</span>
      </div>
      <div class="paginate-box">
        <v-pagination
          v-model="page"
          class="pagination"
          :length="lastPage"
          :total-visible="3"
          @input="fetchTags()"
          color="blue"
          circle
        ></v-pagination>
      </div>
    </div>
    <!-- add tag dialog -->
    <v-dialog v-model="addDialog" width="500">
      <v-card class="card">
        <v-card-title class="card-title">
          <h5>Add New Tag</h5>
        </v-card-title>

        <v-card-text class="mt-2">
          <v-form class="form" v-model="valid" ref="form">
            <p class="my-2 grey--text">
              Please enter the tag information below.
            </p>
            <v-text-field
              rounded
              outlined
              dense
              label="Title"
              v-model="title"
              :rules="[rules.required, rules.maxLength, rules.minLength]"
            ></v-text-field>
            <v-textarea
              outlined
              dense
              rows="3"
              label="Description"
              v-model="description"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="submitAdd"
            :loading="isLoading"
            :disabled="!valid"
          >
            Add tag
          </v-btn>
          <v-btn color="error" text @click="addDialog = false"> Cancle </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add tag dialog -->

    <!-- edit tag dialog -->
    <v-dialog v-model="editDialog" width="500">
      <v-card class="card">
        <v-card-title class="card-title">
          <h5>Edit tag</h5>
        </v-card-title>

        <v-card-text class="mt-2 card_text">
          <v-form class="form" v-model="valid">
            <p class="my-2 grey--text">
              Please enter the tag information below.
            </p>
            <v-text-field
              rounded
              outlined
              dense
              label="Title"
              v-model="editTitle"
              :rules="[rules.required, rules.maxLength, rules.minLength]"
            ></v-text-field>
            <v-textarea
              outlined
              dense
              rows="3"
              label="Description"
              v-model="editDescription"
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="submitEdit" :loading="isLoading">
            Update tag
          </v-btn>
          <v-btn color="error" text @click="editDialog = false"> Cancle </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit tag dialog -->

    
  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      page: 1,
      lastPage: 1,
      total: 0,
      itemSelected: {},
      localTags: [],
      // addDilaog
      addDialog: false,
      title: "",
      description: "",
      
      valid: true,
      // addDilaog

      //editDialog
      editDialog: false,
      editTitle: "",
      editDescription: "",
      //editDialog

      items: [
        {
          name: "Tags",
          disabled: true,
          to: "/home/tag",
        },
      ],
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "Title",
          value: "title",
        },
        { text: "Description", value: "description" },
        { text: "Created at", value: "created_at" },
        { text: "Updated at", value: "updated_at" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  computed: {
    ...mapGetters(["rules"]),
  },
  created() {
    this.fetchTags();
  },
  methods: {
    ...mapActions(["getTags", "createTag", "deleteTag", "editTag"]),
    fetchTags() {
      this.isLoading = true;
      this.getTags()
        .then((result) => {
          this.localTags = result.data.result;
          this.total = this.localTags.length;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    submitAdd() {
      if (this.$refs.form.validate()) {
        let newTag = {
          title: this.title,
          description: this.description,
        };
        this.isLoading = true;
        this.createTag(newTag)
          .then(() => {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.addDialog = false;
            this.fetchTags();
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    submitDelete(item) {
      this.isLoading = true;
      this.deleteTag(item.id)
        .then(() => {
          this.fetchTags();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showEdit(item) {
      this.itemSelected = item;
      this.editDialog = true;
      this.editTitle = item.title;
      this.editDescription = item.description;
    },
    submitEdit() {
      let info = {
        id: this.itemSelected.id,
        title: this.editTitle,
        description: this.editDescription,
      };
      this.isLoading = true;
      this.editTag(info)
        .then(() => {
          this.fetchTags();
        })
        .catch(() => {})
        .finally(() => {
          this.isLoading = false;
          this.editDialog = false;
        });
    },
    formatDate(date) {
      let newDate = new Date(date);
      return newDate.toLocaleString();
    },
  },
};
</script>

<style lang="scss" scoped>
.tags {
  .add-button {
    position: absolute;
    right: 20px;
    right: 30px;
  }
  .table {
    padding-top: 45px;
    .v-icon {
      margin-right: 10px;
    }
  }
  ::v-deep .v-data-table .v-data-table__wrapper table {
    width: 100%;
    min-width: 930px;
  }
}
.card_text {
  height: auto !important;
  overflow: auto;
}
</style>
