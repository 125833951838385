<template>
  <section class="Units pa-3">
    <section class="d-flex justify-space-between">
      <v-breadcrumbs :items="items" divider="/" class="bread-crumb-items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            class="bread-crumb-item"
            :disabled="item.disabled"
            :to="item.to"
          >
            {{ item.name }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="back-button mr-5"
            outlined
            fab
            small
            color="primary"
            v-bind="attrs"
            v-on="on"
            @click="$router.go(-1)"
          >
            <v-icon>mdi-undo-variant</v-icon>
          </v-btn>
        </template>
        <span>Go Back</span>
      </v-tooltip>
    </section>

    <v-card>
      <div class="table-title d-flex">
        <v-icon color="white" class="mr-3">mdi-unity</v-icon>
        <h1>Units List</h1>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="add-button"
              dark
              fab
              small
              color="white"
              v-bind="attrs"
              v-on="on"
              @click="showAdd"
            >
              <v-icon dark color="black"> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Create Unit</span>
        </v-tooltip>
      </div>
      <v-data-table
        :headers="headers"
        :items="localUnits"
        :loading="isLoading"
        :items-per-page="15"
        hide-default-footer
        class="table elevation-1"
      >
        <template v-slot:item.created_at="{ item }">{{
          formatDate(item.created_at)
        }}</template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="showDetail(item)">
                <v-icon color="orange darken-1" small class="icon"
                  >mdi-information</v-icon
                >
              </button>
            </template>
            <span>Show Details</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="showEdit(item)">
                <v-icon color="green" small class="icon">mdi-pen</v-icon>
              </button>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <button v-bind="attrs" v-on="on" @click="submitDelete(item)">
                <v-icon color="red" small class="icon">mdi-delete</v-icon>
              </button>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <div class="table-paginate-box">
      <div class="table-pagination-total">
        {{ total }} <span class="">result(s)</span>
      </div>
      <div class="paginate-box">
        <v-pagination
          v-model="page"
          class="pagination"
          :length="lastPage"
          :total-visible="3"
          @input="fetchUnits()"
          color="blue"
          circle
        ></v-pagination>
      </div>
    </div>
    <!--detail dialog -->
    <v-dialog v-model="detailDialog" width="600">
      <v-card class="card">
        <v-card-title class="card-title">
          <section class="w-100 d-flex justify-space-between align-center">
            <h5>Show Unit Details</h5>
            <div class="d-flex">
              <h3 class="mr-3">
                ID: <span class="white--text">{{ itemSelected.id }}</span>
              </h3>
            </div>
          </section>
        </v-card-title>

        <v-card-text class="mt-2">
          <section
            class="my-3 d-flex justify-space-between align-center grey lighten-3 br-8 px-2 pt-1"
            v-for="(value, name, index) in itemSelected"
            :key="index"
          >
            <h1 class="mr-2">{{ name }}:</h1>
            <h3 v-if="name === 'created_at'">
              {{ formatDate(value) }}
            </h3>
            <h3 v-else-if="name === 'updated_at'">
              {{ value ? formatDate(value) : "------------" }}
            </h3>
            <h3 v-else>
              {{ value }}
            </h3>
          </section>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="detailDialog = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--detail dialog -->
    <!-- add & edit Unit dialog -->
    <v-dialog v-model="dialog" width="500">
      <v-card class="card">
        <v-card-title class="card-title">
          <h5>
            {{ isAddDialog ? "Add New Unit " : "Edit Unit " }}
          </h5>
        </v-card-title>

        <v-card-text class="mt-2 card-text">
          <v-form class="form" ref="form">
            <p class="my-2 grey--text">
              Please enter the Unit information below.
            </p>
            <v-text-field
              rounded
              outlined
              dense
              label="Name"
              v-model="formObj.name"
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              rounded
              outlined
              dense
              type="number"
              label="Price"
              :rules="[rules.required]"
              v-model="formObj.price"
            ></v-text-field>
            <v-textarea
              outlined
              dense
              label="Description"
              v-model="formObj.description"
              rows="3"
            ></v-textarea>
            <!-- image selection -->
            <div
              style="width: 100%"
              class="image-select mb-6"
              v-if="isAddDialog"
            >
              <div
                style="border: 1px solid grey"
                :style="art_portraitReq ? 'border:2px solid red' : ''"
                class="pa-2 rounded"
              >
                <div class="d-flex justify-space-between align-center">
                  <label
                    style="font-size: 15px"
                    :class="art_portraitReq ? 'error--text' : ''"
                    >Art portrait selection</label
                  ><input
                    @change="onChoosePortraitPic"
                    type="file"
                    ref="portraitFileInput"
                    class="d-none"
                    required
                  />
                  <v-btn
                    rounded
                    small
                    class="success"
                    @click="$refs.portraitFileInput.click()"
                    >Add image<v-icon small right
                      >mdi-plus-circle</v-icon
                    ></v-btn
                  >
                </div>
                <p style="font-size: 12px" v-if="formObj.art_portrait">
                  {{ formObj.art_portrait.name }}
                </p>
              </div>
              <p class="image-error error--text" v-if="art_portraitReq">
                *This Field is Required
              </p>
            </div>
            <!-- image selection -->
            <!-- image selection -->
            <div
              style="width: 100%"
              class="image-select mb-6"
              v-if="isAddDialog"
            >
              <div
                style="border: 1px solid grey"
                class="pa-2 rounded"
                :style="art_actionReq ? 'border:2px solid red' : ''"
              >
                <div class="d-flex justify-space-between align-center">
                  <label
                    style="font-size: 15px"
                    :class="art_actionReq ? 'error--text' : ''"
                    >Art action selection</label
                  ><input
                    @change="onChooseActionPic"
                    type="file"
                    ref="actionFileInput"
                    class="d-none"
                  />
                  <v-btn
                    rounded
                    small
                    class="success"
                    @click="$refs.actionFileInput.click()"
                    >Add image<v-icon small right
                      >mdi-plus-circle</v-icon
                    ></v-btn
                  >
                </div>
                <p style="font-size: 12px" v-if="formObj.art_action">
                  {{ formObj.art_action.name }}
                </p>
              </div>
              <p class="image-error error--text" v-if="art_actionReq">
                *This Field is Required
              </p>
            </div>
            <!-- image selection -->
            <v-text-field
              rounded
              outlined
              dense
              type="number"
              label="Defence index"
              v-model="formObj.defence_index"
            ></v-text-field>
            <v-text-field
              rounded
              outlined
              dense
              type="number"
              label="Attack index"
              v-model="formObj.attack_index"
              :rules="[rules.required]"
            ></v-text-field>
            <v-text-field
              rounded
              outlined
              dense
              type="number"
              label="Housing space"
              v-model="formObj.housing_space"
              :rules="[rules.required]"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialog = false"> Cancle </v-btn>
          <v-btn
            color="success"
            text
            @click="submitAdd"
            :loading="isLoading"
            v-if="isAddDialog"
          >
            Add Unit
          </v-btn>
          <v-btn
            v-else
            color="success"
            text
            @click="submitEdit"
            :loading="isLoading"
          >
            Update Unit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- add & edit Unit dialog -->
  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      isLoading: false,
      detailDialog: false,
      page: 1,
      lastPage: 0,
      total: 0,
      itemSelected: {},
      localUnits: [],
      // add&edit Dilaog
      dialog: false,
      isAddDialog: false,
      formObj: {
        name: null,
        price: null,
        description: null,
        art_portrait: null,
        art_action: null,
        defence_index: null,
        attack_index: null,
        housing_space: null,
      },
      art_portraitReq: false,
      art_actionReq: false,
      // add&edit Dilaog

      items: [
        {
          name: "Units",
          disabled: true,
          to: "/home/Units",
        },
      ],
      headers: [
        { text: "ID", align: "start", sortable: false, value: "id" },
        { text: "Name", value: "name" },
        { text: "Price", value: "price" },
        { text: "Defence index", value: "defence_index", align: "center" },
        { text: "Attack index", value: "attack_index", align: "center" },
        { text: "Housing space", value: "housing_space", align: "center" },
        { text: "Description", value: "description",width:'30%'},
        { text: "Created at", value: "created_at" },
        { text: "Actions", value: "actions" },
      ],
    };
  },
  computed: {
    ...mapGetters(["rules"]),
  },
  created() {
    this.fetchUnits();
  },
  methods: {
    ...mapActions([
      "getUnits",
      "createUnit",
      "deleteUnit",
      "editUnit",
    ]),
    fetchUnits() {
      this.isLoading = true;
      let params = new URLSearchParams({ page: this.page, per_page: 15 });
      this.getUnits(params)
        .then((result) => {
          console.log(result);
          this.localUnits = result.data.data;
          this.total = result.data.meta.total;
          this.lastPage = result.data.meta.last_page;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showDetail(item) {
      this.detailDialog = true;
      this.itemSelected = item;
    },
    showAdd() {
      this.resetFormObj();
      this.dialog = true;
      this.isAddDialog = true;
      if (this.$refs.form) this.$refs.form.resetValidation();
    },
    resetFormObj() {
      for (const key in this.formObj) {
        this.formObj[key] = null;
      }
    },
    onChoosePortraitPic(e) {
      this.formObj.art_portrait = e.target.files[0];
      this.art_portraitReq = false;
    },
    onChooseActionPic(e) {
      this.formObj.art_action = e.target.files[0];
      this.art_actionReq = false;
    },
    submitAdd() {
      if (
        this.$refs.form.validate() &&
        this.formObj.art_portrait &&
        this.formObj.art_action
      ) {
        let newUnit = new FormData();
        for (const key in this.formObj) {
          newUnit.append(key, this.formObj[key]);
        }
        this.isLoading = true;
        this.createUnit(newUnit)
          .then(() => {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
          })
          .finally(() => {
            this.dialog = false;
            this.fetchUnits();
            this.snackbar = true;
            this.isLoading = false;
          });
      } else {
        if (!this.formObj.art_portrait) {
          this.art_portraitReq = true;
        }
        if (!this.formObj.art_action) {
          this.art_actionReq = true;
        }
      }
    },
    submitDelete(item) {
      this.isLoading = true;
      this.deleteUnit(item.id)
        .then(() => {
          this.fetchUnits();
        })
        .finally(() => {
          this.snackbar = true;
          this.isLoading = false;
        });
    },
    showEdit(item) {
      this.itemSelected = item;
      this.dialog = true;
      this.isAddDialog = false;
      for (const key in this.formObj) {
        this.formObj[key] = item[key];
      }
    },
    submitEdit() {
      const id = this.itemSelected.id;
      const editedUnit = {};
      for (const key in this.formObj) {
        if(key !== 'art_portrait' && key !== 'art_action'){
          editedUnit[key] = this.formObj[key]
        }
      }
      this.isLoading = true;
      this.editUnit({ id, editedUnit })
        .then(() => {
          this.fetchUnits();
        })
        .catch(() => {})
        .finally(() => {
          this.snackbar = true;
          this.isLoading = false;
          this.dialog = false;
        });
    },
    formatDate(date) {
      let newDate = new Date(date);
      return newDate.toLocaleString();
    },
  },
};
</script>

<style lang="scss" scoped>
.Units {
  .add-button {
    position: absolute;
    right: 20px;
    right: 30px;
  }
  .table {
    padding-top: 45px;
    .v-icon {
      margin-right: 10px;
    }
  }
  ::v-deep .v-data-table .v-data-table__wrapper table {
    width: 100%;
    min-width: 930px;
  }
}
.card-text {
  height: 70vh !important;
  overflow: auto;
}
.image-select {
  position: relative;
  .image-error {
    position: absolute;
    bottom: -20px;
    left: 0;
    font-size: 12px;
  }
}
</style>
